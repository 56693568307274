import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotFound from './NotFound';
import CreateApplication from './CreateApplication';
import SubMenu from './SubMenu';
import Subscriptions from './Subscriptions';
import Invoices from './Invoices';
import Applications from './Applications';
import Logs from './Logs';
import Dashboard from './Dashboard';
import Settings from './Settings';

const TAG = "SubHolder";

class SubHolder extends Component {
  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
  }

  render() {
    // var { user } = this.props;
    // var path = window.location.pathname;
    // printOut(TAG,'user',user);
    return (
      <React.Fragment>
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
              </div>
              <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                  <SubMenu />
                  <Switch>
                    <Route path="/subscriptions/:id" exact component={Subscriptions} />
                    <Route path="/subscriptions" exact component={Subscriptions} />
                    <Route path="/invoices" exact component={Invoices} />
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/settings" exact component={Settings} />
                    <Route path="/logs" exact component={Logs} />
                    {/* <Route path="/applications/create-application" exact component={CreateApplication} /> */}
                    {/* <Route path="/applications" exact component={Applications} /> */}
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLogout: () => {
      // dispatch(logout());
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SubHolder);