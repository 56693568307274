import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
const TAG = "InvoiceListItem";
class InvoiceListItem extends Component {
    render() {
        var { index, invoiceListItem, onGetDetail } = this.props;
        var price = invoiceListItem.amount ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(invoiceListItem.amount) : "N/A";
        return (
            <tr>
                <td className="text-center">{invoiceListItem.apiName}</td>
                <td className="text-center">{invoiceListItem.planName}</td>
                <td className="text-center">{invoiceListItem.createdAt ? invoiceListItem.createdAt.substring(0, invoiceListItem.createdAt.indexOf("T")) : "N/A"}</td>
                <td className="text-center">{invoiceListItem.expiredAt ? invoiceListItem.expiredAt.substring(0, invoiceListItem.expiredAt.indexOf("T")) : "N/A"}</td>
                <td className="text-center">{price}</td>
                <td className="text-center">
                    <div className={`badge badge-light-${invoiceListItem.status === "PAID" ? "success" : (invoiceListItem.status === "EXPIRED" ? "danger" : ((invoiceListItem.status === "REFUNDED" || invoiceListItem.status === "PARTIAL_REFUNDED") ? "dark" : "warning"))}`}>{invoiceListItem.status}</div>
                </td>
                <td>
                    <NavLink to={`/invoices/${invoiceListItem.id}`}>
                        <button className="btn btn-sm btn-light btn-active-light-primary" >Detail</button>
                        {/* onClick={() => onGetDetail(invoiceListItem)} */}
                    </NavLink>
                </td>
            </tr>
        );
    }
}
export default InvoiceListItem;