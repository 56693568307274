import { printOut } from '../actions';
import * as types from './../constants/ActionTypes';

var initialState = {
    success: false,
    pagination:{
        current_page:1,
        size:10,
        total:0
    },
    paymentMethod:"",
    willRedirect:false,
    invoice:{},
    invoices:[],
    message: [],
    data: []
};

const TAG = "invoiceReducer";

const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INVOICE_GET_COMPLETED:
            // printOut(TAG,'INVOICE_GET_COMPLETED',action);
            state = {
                ...state,
                invoice:action.data.data,
                paymentMethod:action.data.data.paymentMethod
            }
            // printOut(TAG,'INVOICE_GET_COMPLETED',state);
            return state;
        case types.INVOICE_LIST_COMPLETED:
            //printOut(TAG,'INVOICE_LIST_COMPLETED',action);
            state = {
                ...state,
                invoices:action.data.data,
                pagination:action.data.metadata.pagination
            }
            return state;
        case types.INVOICE_CREATE_COMPLETED:
            // printOut(TAG,'INVOICE_CREATE_COMPLETED',action);
            state = {
                ...state,
                invoice:action.data.data
            }
            // printOut(TAG,'INVOICE_CREATE_COMPLETED',state);
            return state;
        case types.INVOICE_UPDATE_PAYMENT_COMPLETED:
            // printOut(TAG,'INVOICE_UPDATE_PAYMENT_COMPLETED',action);
            state = {
                ...state,
                willRedirect:true,
                invoice:action.data.data
            }
            // printOut(TAG,'INVOICE_UPDATE_PAYMENT_COMPLETED',state);
            return state;
        case types.PAYMENT_METHOD_CHANGE:
            // printOut(TAG,'PAYMENT_METHOD_CHANGE',action);
            state = {
                ...state,
                ...action.data
                // paymentMethod:action.paymentMethod,
            };
            // printOut(TAG,'PAYMENT_METHOD_CHANGE',state);
            return state;
        case types.INVOICE_RESET_INFO:
            // printOut(TAG,'INVOICE_RESET_INFO',action);
            state = {
                ...state,
                invoice:{}
                // paymentMethod:action.paymentMethod,
            };
            // printOut(TAG,'INVOICE_RESET_INFO',state);
            return state;
        case types.ERRORS_INVOICE_TOO_LATE:
            // printOut(TAG,'ERRORS_INVOICE_TOO_LATE',action);
            state = {
                ...state,
                ...action.data,
                invoice:{
                    ...state.invoice,
                    status:"EXPIRED"
                },
                isLoading: false,
                message: action.data.message,
            };
            // printOut(TAG,'ERRORS_INVOICE_TOO_LATE',state);
            return state;
        default:
            if (action && action.data && action.data.success == true) {
                state = {
                    ...state,
                    ...action.data,
                    isLoading: false,
                    message: []
                }
            }
            else if (action && action.data && action.data.success == false && action.data.message && action.data.message.length > 0) {
                state = {
                    ...state,
                    ...action.data,
                    isLoading: false,
                    message: action.data.message
                }
            }
            return state;
    }
};

export default invoiceReducer;