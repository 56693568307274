export const FAILED = "FAILED";
export const ACTION_FAILED = "ACTION_FAILED";
export const MISSING_HEADER = "MISSING_HEADER";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_MENU = "SHOW_MENU";
export const HIDE_MENU = "HIDE_MENU";
export const SHOW_PROGRESS = "SHOW_PROGRESS";
export const HIDE_PROGRESS = "HIDE_PROGRESS";
export const GENERAL_PARAMETER_NOT_VALID = "GENERAL_PARAMETER_NOT_VALID";
export const GENERAL_BAD_REQUEST = "GENERAL_BAD_REQUEST";

//INVOICE
export const INVOICE_CREATE_COMPLETED = "INVOICE_CREATE_COMPLETED";
export const INVOICE_UPDATE_PAYMENT_COMPLETED = "INVOICE_UPDATE_PAYMENT_COMPLETED";
export const INVOICE_CREATE_FAILED = "INVOICE_CREATE_FAILED";
export const INVOICE_GET_COMPLETED = "INVOICE_GET_COMPLETED";
export const INVOICE_GET_FAILED = "INVOICE_GET_FAILED";
export const INVOICE_LIST_COMPLETED = "INVOICE_LIST_COMPLETED";
export const INVOICE_LIST_FAILED = "INVOICE_LIST_FAILED";
export const PAYMENT_METHOD_CHANGE = "PAYMENT_METHOD_CHANGE";
export const INVOICE_RESET_INFO = "INVOICE_RESET_INFO";
export const ERRORS_INVOICE_TOO_LATE = "ERRORS_INVOICE_TOO_LATE";

//API
export const ERRORS_API_NOTFOUND = "ERRORS_API_NOTFOUND";

//PLAN
export const PLAN_LIST_COMPLETED = "PLAN_LIST_COMPLETED";

//SUBSCRIPTION
export const SUBSCRIPTION_GET_COMPLETED = "SUBSCRIPTION_GET_COMPLETED";
export const SUBSCRIPTION_GET_FAILED = "SUBSCRIPTION_GET_FAILED";
export const SUBSCRIPTION_LIST_COMPLETED = "SUBSCRIPTION_LIST_COMPLETED";
export const SUBSCRIPTION_LIST_FAILED = "SUBSCRIPTION_LIST_FAILED";
export const SUBSCRIPTION_CREATE_COMPLETED = "SUBSCRIPTION_CREATE_COMPLETED";
export const SUBSCRIPTION_CREATE_FAILED = "SUBSCRIPTION_CREATE_FAILED";
export const SUBSCRIPTION_BACK = "SUBSCRIPTION_BACK";
export const SUBSCRIPTION_NEXT = "SUBSCRIPTION_NEXT";
export const PLAN_CHOSEN = "PLAN_CHOSEN";
export const SUBSCRIPTION_RENEW_KEY_COMPLETED = "SUBSCRIPTION_RENEW_KEY_COMPLETED";
export const SUBSCRIPTION_RENEW_KEY_FAILED = "SUBSCRIPTION_RENEW_KEY_FAILED";

//APPLICATION
export const APPLICATION_LIST_COMPLETED = "APPLICATION_LIST_COMPLETED";
export const APPLICATION_GET_COMPLETED = "APPLICATION_GET_COMPLETED";
export const APPLICATION_GET_LOG_COMPLETED = "APPLICATION_GET_LOG_COMPLETED";
export const APPLICATION_GET_ANALYTIC_COMPLETED = "APPLICATION_GET_ANALYTIC_COMPLETED";
export const APPLICATION_GET_SUBSCRIBERS_COMPLETED = "APPLICATION_GET_SUBSCRIBERS_COMPLETED";
export const CHANGE_IN_FORM = "CHANGE_IN_FORM";

// HISTORY
export const HISTORY_GET_COMPLETED = "HISTORY_GET_COMPLETED";

// USER
export const USER_UNAUTHORIZED = "USER_UNAUTHORIZED";
export const TYPE_IN_USER_LIST = "TYPE_IN_USER_LIST";
export const TYPE_IN_LOGIN_FORM = "TYPE_IN_LOGIN_FORM";
export const TYPE_IN_USER_FORM = "TYPE_IN_USER_FORM";
export const TYPE_IN_PASSWORD_FORM = "TYPE_IN_PASSWORD_FORM";
export const TYPE_IN_SALE_PASSWORD_FORM = "TYPE_IN_SALE_PASSWORD_FORM";
export const TYPE_IN_PROFILE_FORM = "TYPE_IN_PROFILE_FORM";
export const USER_GET_COMPLETED = "USER_GET_COMPLETED";
export const USER_GET_FAILED = "USER_GET_FAILED";
export const USER_UPDATE_COMPLETED = "USER_UPDATE_COMPLETED";
export const USER_NOT_FOUND = "USER_NOT_FOUND";
export const USER_EMAIL_REQUIRED = "USER_EMAIL_REQUIRED";
export const USER_EMAIL_PRESENT = "USER_EMAIL_PRESENT";
export const USER_PHONE_TOO_SHORT = "USER_PHONE_TOO_SHORT";
export const USER_CREDENTIAL_NOT_VALID = "USER_CREDENTIAL_NOT_VALID";
export const USER_TOKEN_EXPIRED = "USER_TOKEN_EXPIRED";
export const USER_TOKEN_ALIVE = "USER_TOKEN_ALIVE";
export const USER_TOKEN_INVALID = "USER_TOKEN_INVALID";
export const USER_WRONG_PASSWORD = "USER_WRONG_PASSWORD";
export const USER_PASSWORD_CHANGE_COMPLETED = "USER_PASSWORD_CHANGE_COMPLETED";
export const USER_EDIT = "USER_EDIT";
export const USER_CANCEL_EDIT = "USER_CANCEL_EDIT";
export const USER_NOT_ACTIVATED = "USER_NOT_ACTIVATED";
export const USER_PHONE_EXISTED = "USER_PHONE_EXISTED";
export const USER_PHONE_NOT_VALID = "USER_PHONE_NOT_VALID";
export const USER_ACTIVATION_FAILED = "USER_ACTIVATION_FAILED";
export const USER_ACTIVATION_COMPLETED = "USER_ACTIVATION_COMPLETED";
export const USER_PASSWORD_TOO_SHORT = "USER_PASSWORD_TOO_SHORT";
export const USER_RETYPE_PASSWORD_NOT_MATCH = "USER_RETYPE_PASSWORD_NOT_MATCH";
export const USER_LOGIN_REQUIRED = "USER_LOGIN_REQUIRED";
export const USER_LOGIN_COMPLETED = "USER_LOGIN_COMPLETED";
export const USER_LOGOUT_COMPLETED = "USER_LOGOUT_COMPLETED";
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED";
export const USER_REGISTER_COMPLETED = "USER_REGISTER_COMPLETED";
export const USER_REGISTER_FAILED = "USER_REGISTER_FAILED";
export const USER_CONFIRM_COMPLETED = "USER_CONFIRM_COMPLETED";
export const USER_CONFIRM_FAILED = "USER_CONFIRM_FAILED";
export const USER_NAME_REQUIRED = "USER_NAME_REQUIRED";
export const USER_RESET_PASSWORD_COMPLETED = "USER_RESET_PASSWORD_COMPLETED";
export const USER_RESET_PASSWORD_TOO_FAST = "USER_RESET_PASSWORD_TOO_FAST";
export const ERRORS_USER_EXISTS = "ERRORS_USER_EXISTS";
export const ERRORS_EMAIL_INVALID = "ERRORS_EMAIL_INVALID";
export const TYPE_IN_REGISTER_FORM = "TYPE_IN_REGISTER_FORM";
export const TYPE_IN_RESET_PASSWORD_FORM = "TYPE_IN_RESET_PASSWORD_FORM";
export const USER_UPDATE_LAST_VISIT_URL = "USER_UPDATE_LAST_VISIT_URL";
export const ERRORS_PASSWORDFORMAT_INVALID = "ERRORS_PASSWORDFORMAT_INVALID";
export const CURRENT_VISIT_URL = "CURRENT_VISIT_URL";