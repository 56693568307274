import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
const TAG = "ApplicationListItem";
class ApplicationListItem extends Component {
    render() {
        var { index, applicationListItem, onGetDetail } = this.props;
        return (
            <tr>
                <td className="text-center">{applicationListItem.name}</td>
                <td className="text-center">{applicationListItem.description}</td>
                <td className="text-center">{applicationListItem.createdAt ? applicationListItem.createdAt.substring(0, applicationListItem.createdAt.indexOf("T")) : "N/A"}</td>
                <td>
                    <NavLink to={`/applications/${applicationListItem.id}`}>
                        <button className="btn btn-sm btn-light btn-active-light-primary" >Detail</button>
                        {/* onClick={() => onGetDetail(applicationListItem)} */}
                    </NavLink>
                </td>
            </tr>
        );
    }
}
export default ApplicationListItem;