import * as types from './../constants/ActionTypes';
import * as apiCaller from './../utils/apiCaller';
import { handleError, hideProgress, printOut, saveLocalStorage } from './index';

const TAG = "userAction";
// DETAIL
export const getDetail = () => {
  return dispatch => apiCaller.callApiWithToken('users/detail', 'GET', null, null).then((resp) => {
    // printOut(TAG,'getDetail',resp.data);
    var { data } = resp;
    if (data.success === true) {
      dispatch(getDetailSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
};

export const getDetailSuccess = (data) => {
  return {
    data,
    type: types.USER_GET_COMPLETED
  };
};

// LOGOUT
export function logout() {
  return dispatch => apiCaller.callApiWithToken('users/logout', 'GET', null, null).then((resp) => {
    // printOut(TAG, 'logout', resp.data);
    var { data } = resp;
    if (data.success === true) {
      localStorage.removeItem("x_xsrf_token");
      dispatch(logoutSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function logoutSuccess(data) {
  return { data, type: types.USER_LOGOUT_COMPLETED };
}

export function login(user) {
  return dispatch => apiCaller.callApiWithoutToken('users/login', 'POST', user).then((resp) => {
    // printOut(TAG,'login',resp);
    var { data } = resp;
    var expireAt = Date.now() + 7 * 24 * 60 * 60 * 1000;
    // printOut(TAG, 'expireAt', expireAt);
    saveLocalStorage("x_xsrf_token", resp.headers["x-xsrf-token"], expireAt)
    // localStorage.setItem("x_xsrf_token",resp.headers["x-xsrf-token"]);
    // data.x_xsrf_token = resp.headers["x-xsrf-token"];
    // printOut(TAG,'data',data);
    if (data.success === true) {
      dispatch(loginSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function loginSuccess(data) {
  return { data, type: types.USER_LOGIN_COMPLETED };
}

// REGISTER
export function register(user) {
  return dispatch => apiCaller.callApiWithoutToken('users/register', 'POST', user).then((resp) => {
    //printOut(TAG,'register',resp.data);
    var { data } = resp;
    if (data.success === true) {
      dispatch(registerSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function registerSuccess(data) {
  return { data, type: types.USER_REGISTER_COMPLETED };
}

export function confirm(user) {
  return dispatch => apiCaller.callApiWithoutToken('users/confirm', 'POST', user).then((resp) => {
    //printOut(TAG,'register',resp.data);
    var { data } = resp;
    if (data.success === true) {
      dispatch(confirmSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function confirmSuccess(data) {
  return { data, type: types.USER_CONFIRM_COMPLETED };
}

export function resetPassword(data) {
  return dispatch => apiCaller.callApiWithoutToken('users/reset-password', 'POST', data).then((resp) => {
    //printOut(TAG,'resetPassword',resp.data);
    var { data } = resp;
    if (data.success === true) {
      dispatch(resetPasswordSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function resetPasswordSuccess(data) {
  return { data, type: types.USER_RESET_PASSWORD_COMPLETED };
}

export function update(data) {
  return dispatch => apiCaller.callApiWithToken('users/update', 'POST', data).then((resp) => {
    //printOut(TAG,'update',resp.data);
    var { data } = resp;
    if (data.success === true) {
      dispatch(updateSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function updateSuccess(data) {
  return { data, type: types.USER_UPDATE_COMPLETED };
}

export function changePassword(data) {
  return dispatch => apiCaller.callApiWithToken('users/change-password', 'POST', data).then((resp) => {
    //printOut(TAG,'changePassword',resp.data);
    var { data } = resp;
    if (data.success === true) {
      dispatch(changePasswordSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function changePasswordSuccess(data) {
  return { data, type: types.USER_PASSWORD_CHANGE_COMPLETED };
}

export const typeInProfileForm = (data) => {
  return {
    type: types.TYPE_IN_PROFILE_FORM,
    data
  };
};

export const updateLastVisitUrl = (url) => {
  return {
    type: types.USER_UPDATE_LAST_VISIT_URL,
    url
  };
};

export const typeInRegisterForm = (data) => {
  return {
    type: types.TYPE_IN_REGISTER_FORM,
    data
  };
};

export const typeInResetPasswordForm = (data) => {
  return {
    type: types.TYPE_IN_RESET_PASSWORD_FORM,
    data
  };
};

// LOGIN 
export const typeInLoginForm = (data) => {
  return {
    type: types.TYPE_IN_LOGIN_FORM,
    data
  };
};