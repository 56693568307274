import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainHolder from './components/MainHolder';
import Documentation from './components/Documentation';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Invoice from './components/Invoice';
import VerifyEmail from './components/VerifyEmail';
import SignUpConfirm from './components/SignUpConfirm';
import Welcome from './components/Welcome';
import ResetPassword from './components/ResetPassword';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';
import PasswordChanged from './components/PasswordChanged';
import NotFound from './components/NotFound';
import Documentations from './components/Documentations';
import Error from './components/Error';

class App extends Component {
  componentWillMount() {
    //console.log('componentWillMount');
  }
  render() {
    var themeLightSidebar = {
      "data-kt-app-layout": "light-sidebar",
      "data-kt-app-header-fixed": "true",
      "data-kt-app-sidebar-enabled": "true",
      "data-kt-app-sidebar-fixed": "true",
      "data-kt-app-sidebar-hoverable": "true",
      "data-kt-app-sidebar-push-header": "true",
      "data-kt-app-sidebar-push-toolbar": "true",
      "data-kt-app-sidebar-push-footer": "true",
      "data-kt-app-toolbar-enabled": "true"
    };

    var themeLightHeader = {
      "data-kt-app-layout": "light-header",
      "data-kt-app-header-fixed": "true",
      "data-kt-app-toolbar-enabled": "true"
    };
    var layout;
    if (location.pathname.startsWith("/tutorial") || location.pathname.startsWith("/documentations")) {
      // layout = themeLightSidebar;
    }
    else {
      themeLightHeader
    }
    return (
      <Router>
        <React.Fragment>
          <div className="app-page flex-column flex-column-fluid" style={{height:"100%"}} {...layout} id="kt_app_page" >
            <Switch>
              <Route path="/documentations/:id" exact component={Documentation} />
              <Route path="/invoices/:id" exact component={Invoice} />
              <Route path="/verify-email" exact component={VerifyEmail} />
              <Route path="/password-changed" exact component={PasswordChanged} />
              <Route path="/welcome" exact component={Welcome} />
              <Route path="/error" exact component={Error} />
              <Route path="/users/reset-password" component={ResetPassword} />
              <Route path="/users/reset-password-confirm/:token" component={ResetPasswordConfirm} />
              <Route path="/users/sign-in" exact component={SignIn} />
              <Route path="/users/sign-up" exact component={SignUp} />
              <Route path="/users/sign-up-confirm/:token" exact component={SignUpConfirm} />
              <Route component={MainHolder} />
            </Switch>
          </div>
        </React.Fragment>
      </Router>
    );
  }
}
// Margin
// Margin top: mt-value
// Margin right: me-value
// Margin bottom: mb-value
// Margin left: ms-value

// Padding
// Padding top: pt-value
// Padding right: pe-value
// Padding bottom: pb-value
// Paddig left: ps-value.
// Where the range for the value is 0 to 5

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCheckToken: (data) => {
      // dispatch(checkToken());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

