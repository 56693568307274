import * as types from './../constants/ActionTypes';
import * as apiCaller from './../utils/apiCaller';
import { handleError, hideProgress, printOut } from './index';

const TAG = "applicationAction";
// APP
export function listApplication() {
  return dispatch => apiCaller.callApiWithToken('applications/list', 'GET', null, null).then((resp) => {
    // console.log('listApplication', resp.data);
    var { data } = resp;
    if (data.success === true) {
      dispatch(listApplicationSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

//
export function listApplicationSuccess(data) {
  return { data, type: types.APPLICATION_LIST_COMPLETED };
}

// LOGS
export function getLogs(params) {
  var originUri = params.uri;
  params.uri = params.uri?params.uri.replace(/[.*+?^${}()|/\-[\]\\]/g, '\\\\$&'):"";
  return dispatch => apiCaller.callApiWithToken('applications/get-logs', 'GET', null, params).then((resp) => {
    // console.log('getLogs', resp.data);
    var { data } = resp;
    if (data.success === true) {
      if (data.metadata) {
        // printOut(TAG,"data.metadata",data.metadata);
        data.subscriberId = params.api;
        data.status = params.status;
        data.path = originUri;// Save the origin path typed in the textbox
        data.range = params.range;
        data.metadata.pagination = {
          total_page: Math.ceil(data.metadata.data.total / params.size),
          current_page: params.page,
          size: params.size,
          total: data.metadata.data.total
        };
      }
      dispatch(getLogsSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function getLogsSuccess(data) {
  return { data, type: types.APPLICATION_GET_LOG_COMPLETED };
}

// ANALYTICS
export function getAnalytics(params) {
  return dispatch => apiCaller.callApiWithToken('applications/get-analytics', 'GET', null, params).then((resp) => {
    // console.log('getAnalytics', resp.data);
    var { data } = resp;
    if (data.success === true) {
      if (params.aggs) {
        data.aggs = params.aggs;
      }
      dispatch(getAnalyticsSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function getAnalyticsSuccess(data) {
  return { data, type: types.APPLICATION_GET_ANALYTIC_COMPLETED };
}

// SUBSCRIBERS
export function getSubscribers(params) {
  return dispatch => apiCaller.callApiWithToken('applications/get-subscribers', 'GET', null, params).then((resp) => {
    // console.log('getSubscribers', resp.data);
    var { data } = resp;
    if (data.success === true) {
      if (params.aggs) {
        data.aggs = params.aggs;
      }
      dispatch(getSubscribersSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function getSubscribersSuccess(data) {
  return { data, type: types.APPLICATION_GET_SUBSCRIBERS_COMPLETED };
}

export const changeInForm = (data) => {
  return {
    data,type: types.CHANGE_IN_FORM
  };
};