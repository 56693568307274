import React, { useEffect, Component } from 'react';
import ErrorItem from './ErrorItem';
import InfoItem from './InfoItem';
import { connect } from 'react-redux';
import { printOut, showProgress } from './../actions/index';
import * as _ from 'lodash';
import { changeInForm, getLogs, getSubscribers, listApplication } from '../actions/applicationAction';
import Pagination from 'react-js-pagination';
import LogListItem from './LogListItem';

const TAG = "Logs";

class Logs extends Component {

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
    var { application } = this.props;
    if (application) {
      if (application.applicationId == "") {
        this.props.onListApplication();
      }
      // printOut(TAG, "componentDidMount", application.applicationId);
      var params = {
        applicationId: application.applicationId,
        page: (application && application.pagination && application.pagination.current_page) ? application.pagination.current_page : 1,
        size: (application && application.pagination && application.pagination.size) ? application.pagination.size : 10
      }
      this.props.onGetLogs(params);
      this.props.onGetSubscribers(params);
      const thisComponent = this;
      $("#selStatus").on("change", function (e) {
        thisComponent.onChangeInForm(e);
      });

      $("#selSubscriber").on("change", function (e) {
        thisComponent.onChangeInForm(e);
      });

      $("#selRange").on("change", function (e) {
        thisComponent.onChangeInForm(e);
      });
    }
  }

  onPageChange = (page) => {
    // printOut(TAG, "onPageChange", page);
    var { application } = this.props;
    var to = new Date().getTime();
      var from = to - (parseInt(application.range) * 24 * 60 * 60 * 1000)
    if (application) {
      var params = {
        api: application && application.subscriberId,
        status: application.status,
        uri: application.path,
        to: application.range?to:"",
        from: application.range?from:"",
        range: application.range,
        page: page,
        size: application.pagination.size
      };
      this.props.onGetLogs(params);
    }
  }

  onSizeChange = (e) => {
    var { application } = this.props;
    var to = new Date().getTime();
      var from = to - (parseInt(application.range) * 24 * 60 * 60 * 1000)
    if (application) {
      var params = {
        api: application && application.subscriberId,
        status: application.status,
        uri: application.path,
        to: application.range?to:"",
        from: application.range?from:"",
        range: application.range,
        page: application.pagination.current_page,
        size: parseInt(e.target.value)
      };
      this.props.onGetLogs(params);
    }
  }

  onApplyFilter = (e) => {
    var { application } = this.props;
    if (application) {
      // printOut(TAG, "componentDidMount", application.applicationId);
      var to = new Date().getTime();
      var from = to - (parseInt(application.range) * 24 * 60 * 60 * 1000)
      var params = {
        api: application && application.subscriberId,
        status: application.status,
        uri: application.path,
        to: application.range?to:"",
        from: application.range?from:"",
        range: application.range,
        page: 1,
        size: (application && application.pagination && application.pagination.size) ? application.pagination.size : 10
      }
      // printOut(TAG, 'onApplyFilter', params);
      this.props.onGetLogs(params);
    }
  }

  onReset = (e) => {
    $("#selStatus").val('').trigger('change');
    $("#selSubscriber").val('').trigger('change');
    $("#selRange").val('').trigger('change');

    var { application } = this.props;
    if (application) {
      // printOut(TAG, "componentDidMount", application.applicationId);
      var params = {
        ...application,
        subscriberId: "",
        path: "",
        status: "",
        range: "",
        page: 1,
        size: (application && application.pagination && application.pagination.size) ? application.pagination.size : 10
      }
      // printOut(TAG, 'onReset', params);
      this.props.onChangeInForm(params);
    }
  }

  onChangeInForm = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    var { application } = this.props;
    var newData = {
      ...application,
      [name]: value
    }
    // printOut(TAG, 'onChangeInForm', newData);
    this.props.onChangeInForm(newData);
  }

  escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|/\-[\]\\]/g, '\\\\$&'); // $& means the whole matched string
  }

  render() {
    // KTComponents.init();
    // printOut(TAG, "escapeRegExp", this.escapeRegExp('/api/cnbc/news/v2/list-by-symbol'));
    var { application } = this.props;
    var logs = [], subscribers = [];
    // printOut(TAG, "subscribers", application.subscribers);
    if (application && application.logs && application.logs.length > 0) {
      logs = application.logs.map((keyItem, index) => {
        var item = application.logs[index];
        var logItem = {
          id: item.id,
          timestamp: item.timestamp,
          apiName: application.metadata[item.api].name,
          planName: application.metadata[item.plan].name,
          path: item.path,
          method: item.method,
          status: item.status,
          responseTime: item.responseTime
        };
        return <LogListItem index={index + 1} key={index} logItem={logItem} onGetDetail={this.onGetDetail} />
      });
    }
    if (application && application.subscribers && application.subscribers.length > 0) {
      subscribers = application.subscribers.map((keyItem, index) => {
        // var item = application.subscribers[index];
        // printOut(TAG, "keyItem.id", keyItem.id);
        return <option index={index + 1} key={keyItem.id} value={keyItem.id}>{keyItem.name}</option>
      });
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-flush mb-10">
              <div className="card-header">
                <div className="card-title">
                  {/* <h3>
                    Subscriptions
                  </h3> */}
                </div>
                <div className="card-toolbar">
                  <div className="d-flex justify-content-end"
                    data-kt-subscription-table-toolbar="base">
                    <button type="button" className="btn btn-light-primary"
                      data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                      <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                          viewBox="0 0 24 24" fill="none">
                          <path
                            d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      Filter
                    </button>
                    <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
                      <div className="px-7 py-5">
                        <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                      </div>
                      <div className="separator border-gray-200"></div>
                      <div className="px-7 py-5" data-kt-subscription-table-filter="form">
                        <div className="mb-10">
                          <label className="form-label fs-6 fw-bold">Range</label>
                          <select id="selRange" name="range" className="form-select form-select-solid fw-bolder" value={application.range} onChange={this.onChangeInForm}
                            data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true">
                            <option></option>
                            <option value="1">Last a day</option>
                            <option value="3">Last 3 days</option>
                            <option value="7">Last 7 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="30">Last 30 days</option>
                            <option value="60">Last 60 days</option>
                          </select>
                        </div>
                        <div className="mb-10">
                          <label className="form-label fs-6 fw-bold">API</label>
                          <select id="selSubscriber" name="subscriberId" className="form-select form-select-solid fw-bolder" value={application.subscriberId} onChange={this.onChangeInForm}
                            data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true">
                            <option></option>
                            {subscribers}
                          </select>
                        </div>
                        <div className="mb-10">
                          <label className="form-label fs-6 fw-bold">Status</label>
                          <select id="selStatus" name="status" className="form-select form-select-solid fw-bolder" value={application.status} onChange={this.onChangeInForm}
                            data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true">
                            <option></option>
                            <option value="100">100 - CONTINUE</option>
                            <option value="101">101 - SWITCHING PROTOCOLS</option>
                            <option value="102">102 - PROCESSING</option>
                            <option value="200">200 - OK</option>
                            <option value="201">201 - CREATED</option>
                            <option value="202">202 - ACCEPTED</option>
                            <option value="203">203 - NON AUTHORITATIVE INFORMATION</option>
                            <option value="204">204 - NO CONTENT</option>
                            <option value="205">205 - RESET CONTENT</option>
                            <option value="206">206 - PARTIAL CONTENT</option>
                            <option value="207">207 - MULTI STATUS</option>
                            <option value="300">300 - MULTIPLE CHOICES</option>
                            <option value="301">301 - MOVED PERMANENTLY</option>
                            <option value="302">302 - FOUND</option>
                            <option value="303">303 - SEE OTHER</option>
                            <option value="304">304 - NOT MODIFIED</option>
                            <option value="305">305 - USE PROXY</option>
                            <option value="307">307 - TEMPORARY REDIRECT</option>
                            <option value="400">400 - BAD REQUEST</option>
                            <option value="401">401 - UNAUTHORIZED</option>
                            <option value="402">402 - PAYMENT REQUIRED</option>
                            <option value="403">403 - FORBIDDEN</option>
                            <option value="404">404 - NOT FOUND</option>
                            <option value="405">405 - METHOD NOT ALLOWED</option>
                            <option value="406">406 - NOT ACCEPTABLE</option>
                            <option value="407">407 - PROXY AUTHENTICATION REQUIRED</option>
                            <option value="408">408 - REQUEST TIMEOUT</option>
                            <option value="409">409 - CONFLICT</option>
                            <option value="410">410 - GONE</option>
                            <option value="411">411 - LENGTH REQUIRED</option>
                            <option value="412">412 - PRECONDITION FAILED</option>
                            <option value="413">413 - REQUEST ENTITY TOO LARGE</option>
                            <option value="414">414 - REQUEST URI TOO LONG</option>
                            <option value="415">415 - UNSUPPORTED MEDIA TYPE</option>
                            <option value="416">416 - REQUESTED RANGE NOT SATISFIABLE</option>
                            <option value="417">417 - EXPECTATION FAILED</option>
                            <option value="422">422 - UNPROCESSABLE ENTITY</option>
                            <option value="423">423 - LOCKED</option>
                            <option value="424">424 - FAILED DEPENDENCY</option>
                            <option value="429">429 - TOO MANY REQUESTS</option>
                            <option value="500">500 - INTERNAL SERVER ERROR</option>
                            <option value="501">501 - NOT IMPLEMENTED</option>
                            <option value="502">502 - BAD GATEWAY</option>
                            <option value="503">503 - SERVICE UNAVAILABLE</option>
                            <option value="504">504 - GATEWAY TIMEOUT</option>
                            <option value="505">505 - HTTP VERSION NOT SUPPORTED</option>
                            <option value="507">507 - INSUFFICIENT STORAGE</option>
                          </select>
                        </div>
                        <div className="mb-10">
                          <label className="form-label fs-6 fw-bold">Path</label>
                          <input name="path" type="text" className="form-control form-control-solid" value={application.path} onChange={this.onChangeInForm} placeholder="Ex : /api/yahoo/auto-complete" />
                        </div>
                        <div className="d-flex justify-content-end">
                          <button type="button" className="btn btn-light btn-active-light-primary fw-bold me-2 px-6" onClick={this.onReset}
                            // data-kt-menu-dismiss="true"
                            data-kt-subscription-table-filter="reset">Reset</button>
                          <button type="button" className="btn btn-primary fw-bold px-6" onClick={this.onApplyFilter}
                            data-kt-menu-dismiss="true"
                            data-kt-subscription-table-filter="filter">Apply</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center d-none"
                    data-kt-subscription-table-toolbar="selected">
                    <div className="fw-bolder me-5">
                      <span className="me-2" data-kt-subscription-table-select="selected_count"></span>
                      Selected
                    </div>
                    <button type="button" className="btn btn-danger"
                      data-kt-subscription-table-select="delete_selected">Delete Selected</button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6">
                      <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                          <th className="min-w-125px text-center">Date</th>
                          <th className="min-w-125px text-center">Status</th>
                          <th className="min-w-125px text-center">API</th>
                          <th className="min-w-125px text-center">Plan</th>
                          <th className="min-w-125px text-center">Method</th>
                          <th className="min-w-125px text-center">Path</th>
                          <th className="min-w-125px text-center">Response time</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 fw-bold">
                        {logs}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_length">
                      <label>
                        <select className="form-select form-select-sm form-select-solid" value={(application && application.pagination && application.pagination.size) ? application.pagination.size : 10} onChange={this.onSizeChange}>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={50}>50</option>
                        </select></label>
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination
                        itemClass='paginate_button page-item'
                        disabledClass='disabled'
                        itemClassNext='next'
                        itemClassPrev='previous'
                        linkClass='page-link'
                        activeClass='active'
                        innerClass="pagination"
                        activePage={(application && application.pagination && application.pagination.current_page) ? application.pagination.current_page : 1}
                        itemsCountPerPage={(application && application.pagination && application.pagination.size) ? application.pagination.size : 10}
                        totalItemsCount={(application && application.pagination && application.pagination.total) ? application.pagination.total : 0}
                        onChange={this.onPageChange}
                        pageRangeDisplayed={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    application: state.applicationReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onListApplication: () => {
      dispatch(showProgress());
      dispatch(listApplication());
    },
    onGetLogs: (params) => {
      dispatch(showProgress());
      dispatch(getLogs(params));
    },
    onGetSubscribers: (params) => {
      dispatch(showProgress());
      dispatch(getSubscribers(params));
    },
    onChangeInForm: (params) => {
      dispatch(changeInForm(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logs);

