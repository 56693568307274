import * as types from './../constants/ActionTypes';
import * as apiCaller from './../utils/apiCaller';
import { handleError, hideProgress } from './index';

export function listInvoice(params) {
  return dispatch => apiCaller.callApiWithToken('invoices/list', 'GET', null, params).then((resp) => {
    var { data } = resp;
    if (data.success === true) {
      dispatch(listInvoiceSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function listInvoiceSuccess(data) {
  return { data, type: types.INVOICE_LIST_COMPLETED };
}

export function createInvoice(params) {
  return dispatch => apiCaller.callApiWithToken('invoices/create', 'GET', null, params).then((resp) => {
    var { data } = resp;
    if (data.success === true) {
      dispatch(createInvoiceSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function createInvoiceSuccess(data) {
  return { data, type: types.INVOICE_CREATE_COMPLETED };
}

export function updateInvoicePayment(params) {
  return dispatch => apiCaller.callApiWithToken('invoices/update-payment', 'GET', null, params).then((resp) => {
    var { data } = resp;
    if (data.success === true) {
      dispatch(updateInvoicePaymentSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function updateInvoicePaymentSuccess(data) {
  return { data, type: types.INVOICE_UPDATE_PAYMENT_COMPLETED };
}

export function getInvoiceDetail(params) {
  return dispatch => apiCaller.callApiWithToken('invoices/detail', 'GET', null, params).then((resp) => {
    var { data } = resp;
    if (data.success === true) {
      dispatch(getInvoiceDetailSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function getInvoiceDetailSuccess(data) {
  return { data, type: types.INVOICE_GET_COMPLETED };
}

export const changePaymentMethod = (data) => {
  return {
    data,type: types.PAYMENT_METHOD_CHANGE
  };
};

// Use to remove the invoice object after redirecting to invoice page
export const resetInvoiceInfo = () => {
  return {
    type: types.INVOICE_RESET_INFO
  };
};