import * as types from './../constants/ActionTypes';
import * as Config from './../constants/Config';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import Base64Encoder from 'crypto-js/enc-base64';
const TAG = "indexAction";

export const changeCurrentUrl = (url) => {
  return {
    url, type: types.CURRENT_VISIT_URL
  };
};

export const showProgress = () => {
  KTApp.showPageLoading();
  return {
    type: types.SHOW_PROGRESS
  };
};

export const hideProgress = () => {
  KTApp.hidePageLoading();
  KTComponents.init();
  // KTMenu.init(); // init all KT components inclusing menu at corner
  return {
    type: types.HIDE_PROGRESS
  };
};

export const showModal = (data) => {
  return {
    data, type: types.SHOW_MODAL
  };
};

export const hideModal = (data) => {
  return {
    data, type: types.HIDE_MODAL
  };
};

export const generateHash = (params) => {
  // console.log("params",params);
  var uuid = uuidv4();
  var sortedParams = Object.keys(params).sort().reduce((r, k) => (r[k] = params[k], r), {});
  // console.log("sortedParams",sortedParams);
  var content = uuid;
  Object.keys(sortedParams).forEach(function (key) {
    if(sortedParams[key])
    {
      content += key + sortedParams[key];
    }
  });
  // console.log("content",content);
  var hash = Base64Encoder.stringify(CryptoJS.HmacSHA256(content, `${Config.SK}`));
  // console.log("hash",hash);
  return {
    uuid: uuid,
    hash: hash
  };
};

// handle general error
export function handleError(data) {
  //console.log(data);
  var k;
  Object.keys(data.error).forEach(function (key) {
    k = key
  });
  return {
    data, type: k
  };
}

export function printOut(TAG, name, data,) {
  console.log(TAG + " - " + name, data);
}

export function saveLocalStorage(key, value, expireAt) {
  if (expireAt) {
    var data = {
      expireAt: expireAt,
      value: value
    };
    localStorage.setItem(key, JSON.stringify(data));
  }
  else {
    localStorage.setItem(key, value);
  }
}

export function getLocalStorage(key) {
  var data = localStorage.getItem(key);
  // printOut(TAG,key,typeof data);
  if (data && typeof data === 'string') {//&& data instanceof String
    var json = JSON.parse(data);
    if (json.expireAt) {
      if (json.expireAt < Date.now()) {
        localStorage.removeItem(key);
        return null;
      }
      else
        return json.value;
    }
    else
      return data;
  }
  else
    return null;
}