import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showProgress } from './../actions/index';
import Pagination from 'react-js-pagination';
import * as Config from './../constants/Config';
import * as turf from '@turf/turf'
import { Link } from 'react-router-dom';
// import { useHistory } from "react-router-dom";

class Documentations extends Component {
  constructor(props) {
    super(props);
  }

  // componentWillMount() {
  //   console.log("componentWillMount");
  // }

  componentDidMount() {
    // console.log("componentDidMount");
  }

  onShowModal = () => {
    // console.log("onShowModal");
    this.props.onShowHideModal(true);
  }

  onHideModal = () => {
    // console.log("onHideModal");
    this.props.onShowHideModal(false);
  }

  render() {
    return (
      <React.Fragment>
        <div className="d-flex flex-column flex-column-fluid my-10">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-xxl">
              <div className="card" id="kt_pricing">
                <div className="card-body p-lg-17">
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">Finance API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/schwab" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Schwab API<br /> Document</div>
                                  </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/morningstar" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Morning Star API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/cnbc" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">CNBC API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/fidelity" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Fidelity API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/seekingalpha" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Seeking Alpha API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/investingcrypto" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Investing Crypto API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/bloomberg" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Bloomberg API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/yahoo" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Yahoo Finance API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/webull" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Webull API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/tradingview" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Trading View API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">E-commerce API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/asos" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Asos API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/f21" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Forever21 API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/zappos" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Zappos API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/target" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Target API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/sephora" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Sephora API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/kohls" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Kohls API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/walmart" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Walmart API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/hm" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">H&M API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/shein" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">SHEIN API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/wayfair" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Wayfair API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">Real Estate API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/realtor" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Realtor API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/realtorca" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Realtor CA API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/idealista" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Idealista API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/zoopla" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Zoopla API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/realestateau" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Real Estate AU API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/bayut" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Bayut API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/redfin" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Redfin API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">Sport API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/livescore" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Livescore API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/transfermarkt" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Transfermarkt API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/cricbuzz" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Cricbuzz API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">Entertaiment API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/imdb" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">IMDb API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/flixster" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Flixter (Rotten Tomatoes) <br />API Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/webtoon" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Webtoon API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">Transportation API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/17track" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">17track API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/trackingmore" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Trackingmore API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/flightradar" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">FlightRadar24 API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">Traveling API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/tripadvisor" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">TripAdvisor API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/hotels" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Hotels API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/thefork" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">TheFork API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/booking" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Booking API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">Reviews API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/consumerreports" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Consumer Reports API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/trustpilot" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Trust Pilot API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">AI & ML API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/shazam" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Shazam API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/photomath" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Photomath API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">Weather API Document</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/theweatherchannel" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">The Weather Channel API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card h-100">
                              <div
                                className="card-body d-flex justify-content-center text-center flex-column p-8">
                                <a href="/documentations/airvisual" target="_blank"
                                  className="text-gray-800 text-hover-primary d-flex flex-column">
                                  <div className="symbol symbol-75px mb-5">
                                    <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                                  </div>
                                  <div className="fs-5 fw-bolder mb-2">Air Visual API<br /> Document</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    map: state.mapReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onTypeInSearchForm: (data) => {
      dispatch(typeInSearchForm(data));
    },
    onShowHideModal: (state) => {
      dispatch(showHideModal(state));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documentations);
