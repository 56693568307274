import React, { Component } from 'react';

class InfoItem extends Component {
    render()
    {
        return (
            <div className="alert alert-info  text-center">
                {this.props.msg}
            </div>
        );
    }
}
export default InfoItem;