import React, { Component } from 'react';
import { connect } from 'react-redux';

class Contact extends Component {
  constructor(props) {
    super(props);
  }

  // componentWillMount() {
  //   console.log("componentWillMount");
  // }

  componentDidMount() {
    // console.log("componentDidMount");
  }

  onShowModal = () => {
    // console.log("onShowModal");
    this.props.onShowHideModal(true);
  }

  onHideModal = () => {
    // console.log("onHideModal");
    this.props.onShowHideModal(false);
  }

  render() {
    return (
      <React.Fragment>
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1
                      className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                      Contact</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                      <li className="breadcrumb-item text-muted">
                        <a href="#"
                          className="text-muted text-hover-primary">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                      </li>
                      <li className="breadcrumb-item text-muted">Contact</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                  <div className="card" id="kt_contact">
                    <div className="card-body p-lg-17">
                      <div className="d-flex flex-column">
                        <div className="mb-13 text-center">
                          <h3 className="fs-1hx fw-bold mb-5">We currently only support via emails and tickets. <br />We’re happy to answer any questions you may have, just send us an e-mail.</h3>
                          <div className="text-gray-400 fw-semibold fs-5">
                            <a target="_blank" href="mailto:apidojo@gmail.com">apidojo@gmail.com</a> - Project Lead
                          </div>
                        </div>
                        {/* <div>
                          <form action="" className="form" method="post" id="kt_contact_form">
                            <div className="row mb-5">
                              <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Name</label>
                                <input type="text" className="form-control form-control-solid" placeholder="" name="name" />
                              </div>
                              <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Email</label>
                                <input type="text" className="form-control form-control-solid" placeholder="" name="email" />
                              </div>
                            </div>
                            <div className="d-flex flex-column mb-5 fv-row">
                              <label className="fs-5 fw-bold mb-2">Subject</label>
                              <input className="form-control form-control-solid" placeholder="" name="subject" />
                            </div>
                            <div className="d-flex flex-column mb-10 fv-row">
                              <label className="fs-6 fw-bold mb-2">Message</label>
                              <textarea className="form-control form-control-solid" rows="6" name="message" placeholder=""></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary" id="kt_contact_submit_button">
                              <span className="indicator-label">Send Feedback</span>
                              <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                          </form>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    map: state.mapReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onTypeInSearchForm: (data) => {
      dispatch(typeInSearchForm(data));
    },
    onShowHideModal: (state) => {
      dispatch(showHideModal(state));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
