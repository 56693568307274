import { printOut } from '../actions';
import * as types from './../constants/ActionTypes';

var initialState = {
    isLoading: false,
    currentUrl:""
};

const TAG = "indexReducer";

const indexReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case types.CURRENT_VISIT_URL:
            // printOut(TAG, 'CURRENT_VISIT_URL', action);
            state = {
                ...state,
                currentUrl:action.url
            };
            // printOut(TAG, 'CURRENT_VISIT_URL', state);
            return state;
        case types.SHOW_PROGRESS:
            // printOut(TAG, 'SHOW_PROGRESS', action);
            state = {
                ...state,
                isLoading: true
            };
            return state;
        case types.HIDE_PROGRESS:
            // printOut(TAG, 'HIDE_PROGRESS', action);
            state = {
                ...state,
                isLoading: false
            };
            return state;
        case types.SHOW_MODAL:
            // printOut(TAG, 'SHOW_MODAL', action);
            state = {
                ...state,
                open: true
            };
            return state;
        case types.HIDE_MODAL:
            // printOut(TAG, 'HIDE_MODAL', action);
            state = {
                ...state,
                open: false
            };
            return state;
        default:
            return state;
    }
};

export default indexReducer;