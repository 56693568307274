import React, { Component } from 'react';
import ErrorItem from './ErrorItem';
import InfoItem from './InfoItem';
import { connect } from 'react-redux';
import { getDetail, update, changePassword, typeInProfileForm } from './../actions/userAction';
import { printOut, showProgress } from './../actions/index';
import * as _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { changeInForm, getAnalytics } from '../actions/applicationAction';


const TAG = "Dashboard";

class Dashboard extends Component {
  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
    var paramsApi = {
      type: "date_histo",
      aggs: "field:api"
    };
    this.props.onGetAnalytics(paramsApi);
    var paramsStatus = {
      type: "date_histo",
      aggs: "field:status"
    };
    this.props.onGetAnalytics(paramsStatus);
    var paramsRespTime = {
      type: "date_histo",
      aggs: "avg:response-time"
    };
    const thisComponent = this;
    this.props.onGetAnalytics(paramsRespTime);
    $("#selRange").on("change", function (e) {
      thisComponent.onChangeInForm(e);
    });
  }

  onApplyFilter = (e) => {
    var { application } = this.props;
    // printOut(TAG, 'onApplyFilter', application);
    if (application) {
      var to = new Date().getTime();
      var from = to - ((application.range?parseInt(application.range):7) * 24 * 60 * 60 * 1000)
      var paramsApi = {
        api: (application && application.applicationId?application.applicationId:""),
        to: to,
        from: from,
        type: "date_histo",
        aggs: "field:api"
      };
      this.props.onGetAnalytics(paramsApi);
      var paramsStatus = {
        api: (application && application.applicationId?application.applicationId:""),
        to: to,
        from: from,
        type: "date_histo",
        aggs: "field:status"
      };
      this.props.onGetAnalytics(paramsStatus);
      var paramsRespTime = {
        api: (application && application.applicationId?application.applicationId:""),
        to: to,
        from: from,
        type: "date_histo",
        aggs: "avg:response-time"
      };
      // printOut(TAG, 'onApplyFilter', paramsApi);
      this.props.onGetAnalytics(paramsRespTime);
    }
  }

  onReset = (e) => {
    $("#selRange").val('').trigger('change');
    var { application } = this.props;
    if (application) {
      var params = {
        ...application,
        range: ""
      }
      this.props.onChangeInForm(params);
    }
  }

  onChangeInForm = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    var { application } = this.props;
    var newData = {
      ...application,
      [name]: value
    }
    // printOut(TAG, 'onChangeInForm', newData);
    this.props.onChangeInForm(newData);
  }

  render() {
    var { application } = this.props;
    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      }
    });
    // printOut(TAG, "render", application)
    return (
      <React.Fragment>
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header">
                <div className="card-title">
                  {/* <h3>
                    Subscriptions
                  </h3> */}
                </div>
                <div className="card-toolbar">
                  <div className="d-flex justify-content-end"
                    data-kt-subscription-table-toolbar="base">
                    <button type="button" className="btn btn-light-primary"
                      data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                      <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                          viewBox="0 0 24 24" fill="none">
                          <path
                            d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      Filter
                    </button>
                    <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
                      <div className="px-7 py-5">
                        <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                      </div>
                      <div className="separator border-gray-200"></div>
                      <div className="px-7 py-5" data-kt-subscription-table-filter="form">
                        <div className="mb-10">
                          <label className="form-label fs-6 fw-bold">Range</label>
                          <select id="selRange" name="range" className="form-select form-select-solid fw-bolder" 
                            value={application.range} onChange={this.onChangeInForm}
                            data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true">
                            <option></option>
                            <option value="1">Last a day</option>
                            <option value="3">Last 3 days</option>
                            <option value="7">Last 7 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="30">Last 30 days</option>
                            <option value="60">Last 60 days</option>
                          </select>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button type="button" className="btn btn-light btn-active-light-primary fw-bold me-2 px-6" 
                            onClick={this.onReset}
                            data-kt-subscription-table-filter="reset">Reset</button>
                          <button type="button" className="btn btn-primary fw-bold px-6" 
                            onClick={this.onApplyFilter}
                            data-kt-menu-dismiss="true"
                            data-kt-subscription-table-filter="filter">Apply</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center d-none"
                    data-kt-subscription-table-toolbar="selected">
                    <div className="fw-bolder me-5">
                      <span className="me-2" data-kt-subscription-table-select="selected_count"></span>
                      Selected
                    </div>
                    <button type="button" className="btn btn-danger"
                      data-kt-subscription-table-select="delete_selected">Delete Selected</button>
                  </div>
                </div>
              </div>
          <div className="card-body p-9">
            <div className="row mb-7 text-center">
              <label className="fs-4 text-gray-600">Hits by API</label>
              <HighchartsReact
                highcharts={Highcharts}
                options={application.dataApi}
              />
              <label className="fs-4 text-gray-600">Response times</label>
              <HighchartsReact
                highcharts={Highcharts}
                options={application.dataResponseTime}
              />
              <label className="fs-4 text-gray-600">Response Status</label>
              <HighchartsReact
                highcharts={Highcharts}
                options={application.dataStatus}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    application: state.applicationReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetAnalytics: (data) => {
      dispatch(showProgress());
      dispatch(getAnalytics(data));
    },
    onChangeInForm: (params) => {
      dispatch(changeInForm(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

