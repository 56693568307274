import React, { Component } from 'react';
import ErrorItem from './ErrorItem';
import InfoItem from './InfoItem';
import { connect } from 'react-redux';
import { printOut, showProgress } from './../actions/index';
import * as _ from 'lodash';
import { listApplication } from '../actions/applicationAction';
import Pagination from 'react-js-pagination';
import ApplicationListItem from './ApplicationListItem';
// import ApplicationListItem from './ApplicationListItem';

const TAG = "Applications";

class Applications extends Component {
  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
    var { applicationList } = this.props;
    var params = {
      page: (applicationList && applicationList.pagination && applicationList.pagination.current_page)?applicationList.pagination.current_page:1,
      size: (applicationList && applicationList.pagination && applicationList.pagination.size)?applicationList.pagination.size:0
    }
    this.props.onListApplication(params);
  }

  onGetDetail = (application) => {
    // printOut(TAG, "onDetail", application);
    // var params = {
    //   applicationId: application.id
    // }
    // this.props.onGetDetail(params);
  }

  onCreateApplication = () => {
    
  }

  onPageChange = (page) => {
    // printOut(TAG, "onPageChange", page);
    // var { applicationList } = this.props;
    // if (applicationList) {
    //   var params = {
    //     page: page,
    //     size: applicationList.pagination.size
    //   };
    //   this.props.onListApplication(params);
    // }
  }

  onSizeChange = (e) => {
    // var { applicationList } = this.props;
    // if (applicationList) {
    //   var params = {
    //     page: applicationList.pagination.current_page,
    //     size: e.target.value
    //   };
    //   this.props.onListApplication(params);
    // }
  }

  render() {
    var { user, applicationList } = this.props;
    // printOut(TAG, "applicationList", applicationList)
    var applications = [], keys = [];
    if (applicationList && applicationList.data && applicationList.data.length > 0) {
      applications = applicationList.data.map((keyItem, index) => {
        var item = applicationList.data[index];
        // printOut(TAG, "item", item)
        // printOut(TAG, "applicationList.metadata[item.api].name", applicationList.metadata[item.api].name)
        var applicationListItem = {
          id: item.id,
          name: item.name,
          description:item.description,
          createdAt: item.created_at
        };
        return <ApplicationListItem index={index + 1} key={index} applicationListItem={applicationListItem} onGetDetail={this.onGetDetail} />
      });
    }
    return (
      <React.Fragment>
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1
                      className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                      Applications</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                      <li className="breadcrumb-item text-muted">
                        <a href="#"
                          className="text-muted text-hover-primary">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                      </li>
                      <li className="breadcrumb-item text-muted">Applications</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card card-flush mb-10">
                      <div className="card-header">
                          <div className="card-title">
                            <h3>
                              Subscriptions
                            </h3>
                          </div>
                          <div className="card-toolbar">
                            <div className="d-flex justify-content-end"
                              data-kt-subscription-table-toolbar="base">
                              <button type="button" className="btn btn-primary" onClick={this.onCreateApplication}>Create an application</button>
                            </div>
                            <div className="d-flex justify-content-end align-items-center d-none"
                              data-kt-subscription-table-toolbar="selected">
                              <div className="fw-bolder me-5">
                                <span className="me-2"
                                  data-kt-subscription-table-select="selected_count"></span>Selected
                              </div>
                              <button type="button" className="btn btn-danger"
                                data-kt-subscription-table-select="delete_selected">Delete
                                Selected</button>
                            </div>
                          </div>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <table className="table align-middle table-row-dashed fs-6 gy-5">
                              <thead>
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                  <th className="min-w-125px text-center">Name</th>
                                  <th className="min-w-125px text-center">Description</th>
                                  <th className="min-w-125px text-center">Create at</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className="text-gray-600 fw-bold">
                                {
                                  applications.length > 0?
                                  applications
                                  :
                                  <tr>
                                    <td colSpan={6} className="text-center fs-5">There is no data available</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                              <div className="dataTables_length">
                                <label>
                                  <select className="form-select form-select-sm form-select-solid" value={10} onChange={this.onSizeChange}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                  </select></label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                              <div className="dataTables_paginate paging_simple_numbers">
                                <Pagination
                                  itemClass='paginate_button page-item'
                                  disabledClass='disabled'
                                  itemClassNext='next'
                                  itemClassPrev='previous'
                                  linkClass='page-link'
                                  activeClass='active'
                                  innerClass="pagination"
                                  activePage={(applicationList && applicationList.pagination && applicationList.pagination.current_page)?applicationList.pagination.current_page:1}
                                  itemsCountPerPage={(applicationList && applicationList.pagination && applicationList.pagination.size)?applicationList.pagination.size:10}
                                  totalItemsCount={(applicationList && applicationList.pagination && applicationList.pagination.total)?applicationList.pagination.total:0}
                                  onChange={this.onPageChange}
                                  pageRangeDisplayed={10}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    applicationList: state.applicationReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onListApplication: (params) => {
      dispatch(showProgress());
      dispatch(listApplication(params));
    },
    onGetDetail: (params) => {
      dispatch(getApplicationDetail(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Applications);