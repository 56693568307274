import React, { Component } from 'react';
import { connect } from 'react-redux';

class Home extends Component {
  constructor(props) {
    super(props);
  }

  // componentWillMount() {
  //   console.log("componentWillMount");
  // }

  componentDidMount() {
    // console.log("componentDidMount");
  }

  onShowModal = () => {
    // console.log("onShowModal");
    this.props.onShowHideModal(true);
  }

  onHideModal = () => {
    // console.log("onHideModal");
    this.props.onShowHideModal(false);
  }

  render() {
    return (
      <React.Fragment>
        <div className="mb-0" id="home">
          <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
            style={{ backgroundImage: 'assets/media/svg/illustrations/landing.svg' }}>
            <div className="landing-header"></div>
            <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
              <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-15">All APIs from
                  <br />many famous brands
                  <span
                    style={{ background: 'linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                    <span id="kt_landing_hero_text"> in one place</span>
                  </span>
                </h1>
              </div>
              <div className="d-flex flex-center flex-wrap position-relative px-5">
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="bloomberg">
                  <img src="assets/media/logos/bloomberg.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="cnbc">
                  <img src="assets/media/logos/cnbc.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="fidelity">
                  <img src="assets/media/logos/fidelity.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="investing">
                  <img src="assets/media/logos/investing.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="morning">
                  <img src="assets/media/logos/morning.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
              </div>
              <div className="d-flex flex-center flex-wrap position-relative px-5">
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="schwab">
                  <img src="assets/media/logos/schwab.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="seeking alpha">
                  <img src="assets/media/logos/seekingalpha.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="tradingview">
                  <img src="assets/media/logos/tradingview.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="webull">
                  <img src="assets/media/logos/webull.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
                <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="yahoo">
                  <img src="assets/media/logos/yahoo.png" className="mh-30px mh-lg-40px" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="currentColor"></path>
            </svg>
          </div>
        </div>
        <div className="mb-n10 mb-lg-n20 z-index-2">
          <div className="container">
            <div className="text-center mb-17">
              <h3 className="fs-2hx text-dark mb-5" id="how-it-works">
                Well-trained bots helping you to get instant live data</h3>
              <div className="fs-2 text-muted fw-bold">with top features
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 px-5">
                <div className="text-center my-5 mb-md-0">
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">1</span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Live official data</div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">What data you get
                    <br />are as same as on the official sites, and in real time.
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center my-5 mb-md-0">
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">2</span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Well structural data</div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">Body response is in form of<br />JSON format.
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center my-5 mb-md-0">
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">3</span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Fast request/response time</div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">Fast Response speed
                    <br />which is far from any other
                    <br />crawling techniques, services.
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center my-5 mb-md-0">
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">4</span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Proxy layer included</div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">Our service has already
                    <br />embeded an automated proxy layer
                    <br />in case of need.
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center my-5 mb-md-0">
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">5</span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Overcome bot defender</div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">Our bots has already
                    <br />trained to overcome 
                    <br />bot defender layer.
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center my-5 mb-md-0">
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">6</span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Reasonable costing</div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">Our service has best
                    <br />price on the market 
                    <br/>delivering all-in-one solutions.
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center my-5 mb-md-0">
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">7</span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Access to future APIs</div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">You will get access
                    <br />to future released APIs
                    <br />at no cost as long as
                    <br />the API in the same group.
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center my-5 mb-md-0">
                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">8</span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Ease to get started and use</div>
                  </div>
                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">Subscribe to a plan,
                    <br />generate a token key,
                    <br />and make your first request.
                  </div>
                </div>
              </div>
            </div>
            <div className="tns tns-default">
              <div>
                <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                  <img src="assets/media/preview/demos/demo1/light-ltr.png"
                    className="card-rounded shadow mh-lg-650px mw-100" alt="" />
                </div>
                <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                  <img src="assets/media/preview/demos/demo2/light-ltr.png"
                    className="card-rounded shadow mh-lg-650px mw-100" alt="" />
                </div>
                <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                  <img src="assets/media/preview/demos/demo4/light-ltr.png"
                    className="card-rounded shadow mh-lg-650px mw-100" alt="" />
                </div>
                <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10">
                  <img src="assets/media/preview/demos/demo5/light-ltr.png"
                    className="card-rounded shadow mh-lg-650px mw-100" alt="" />
                </div>
              </div>
              {/* <button className="btn btn-icon btn-active-color-primary" id="kt_team_slider_prev1">
                <i className="ki-duotone ki-left fs-2x"></i>
              </button>
              <button className="btn btn-icon btn-active-color-primary" id="kt_team_slider_next1">
                <i className="ki-duotone ki-right fs-2x"></i>
              </button> */}
            </div>
          </div>
        </div>
        <div className="mt-sm-n10">
          <div className="landing-curve landing-dark-color">
            <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="currentColor"></path>
            </svg>
          </div>
          <div className="pb-15 pt-18 landing-dark-bg">
            <div className="container">
              <div className="text-center mt-15 mb-18" id="achievements">
                <h3 className="fs-2hx text-white fw-bold mb-5">We Make Things Easier</h3>
                <div className="fs-2 text-gray-700 fw-bold">Save time, effort, and thousands of bucks
                  <br />by calling endpoints to get data instead of doing everything on your own.
                </div>
              </div>
              <div className="d-flex flex-center">
                <div className="d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px">
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{ backgroundImage: 'assets/media/svg/misc/octagon.svg' }}>
                    <i className="ki-duotone ki-element-11 fs-2tx text-white mb-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>
                    <div className="mb-0">
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div className="min-w-70px" data-kt-countup="true" data-kt-countup-value="55"
                          data-kt-countup-suffix="K+">0</div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">Developers registered</span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{ backgroundImage: 'assets/media/svg/misc/octagon.svg' }}>
                    <i className="ki-duotone ki-chart-pie-4 fs-2tx text-white mb-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                    <div className="mb-0">
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div className="min-w-70px" data-kt-countup="true" data-kt-countup-value="600"
                          data-kt-countup-suffix="K+">0</div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">Requests per day</span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{ backgroundImage: 'assets/media/svg/misc/octagon.svg' }}>
                    <i className="ki-duotone ki-basket fs-2tx text-white mb-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>
                    <div className="mb-0">
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div className="min-w-70px" data-kt-countup="true" data-kt-countup-value="20"
                          data-kt-countup-suffix="K+">0</div>
                      </div>
                      <span className="text-gray-600 fw-semibold fs-5 lh-0">Subscriptions</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fs-2 fw-semibold text-muted text-center mb-3">
                We provides APIs which reproduces PUBLIC data and features of related famous sites/brands through a set of powerful and developer-friendly endpoints. You are going to get almost everything that you see PUBLICLY on the sites/applications. The response data is always live and synced with the site in real time; We do not store or cache data locally.
              </div>
            </div>
          </div>
          <div className="landing-curve landing-dark-color">
            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="currentColor"></path>
            </svg>
          </div>
        </div>
        <div className="py-10 py-lg-20">
          <div className="container">
            <div className="text-center mb-12">
              <h3 className="fs-2hx text-dark mb-5" id="team">APIs from famous BRAND</h3>
            </div>
            <div className="row">
              <div className="col-md-3 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Yahoo Finance</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">CNBC</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Bloomberg</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Morning Star</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Investing Crypto</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Fidelity Investments</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Schwab</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Seeking Alpha</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Trading View</div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">SHEIN</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Asos</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Forever21</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">H&M</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Zappos</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Walmart</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Target</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Consumer Reports</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Trust Pilot</div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">17tracking</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">TrackingMore</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">FlightRadar24</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">AirVisual</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Tasty</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Yummly</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Photomath</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Shazam</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">LiveScore</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Sofascore</div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-5">
                <div className="text-center mb-10 mb-md-0">
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Hotels</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">TripAdvisor</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">TheFork</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">IMDb</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Flixster/Rotten Tomatoes</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Realtor.com</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Realtor.ca</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">RealEstate.com.au</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Zoopla</div>
                  </div>
                  <div className="d-flex mb-5">
                    <span className="badge badge-circle badge-success me-3 fs-3"></span>
                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Idealista</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    map: state.mapReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onTypeInSearchForm: (data) => {
      dispatch(typeInSearchForm(data));
    },
    onShowHideModal: (state) => {
      dispatch(showHideModal(state));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
