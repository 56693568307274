import React, { Component } from 'react';
import { connect } from 'react-redux';
import { typeInRegisterForm, confirm } from './../actions/userAction';
import { printOut, showProgress } from './../actions/index';
import { Redirect } from 'react-router-dom';
import InfoItem from './InfoItem';
import ErrorItem from './ErrorItem';

const TAG = "SignUpConfirm";

class SignUpConfirm extends Component {
  componentWillMount() {
    // printOut(TAG,'token before', this.props.match.params.token);
    var parsedJwt = this.onParseJwt(this.props.match.params.token);
    // printOut(TAG,'parsedJwt', parsedJwt);
    var { user } = this.props;
    var newData = {
      ...user,
      email: parsedJwt.email,
      lastname: parsedJwt.lastname,
      firstname: parsedJwt.firstname,
      token: this.props.match.params.token
    }
    this.props.onTypeInRegisterForm(newData);
  }

  onParseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  onChange = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    var { user } = this.props;
    var newData = {
      ...user,
      [name]: value
    }
    this.props.onTypeInRegisterForm(newData);
  }

  onSubmit = (e) => {
    // e.preventDefault();
    var { user } = this.props;
    let params = {
      token: user.token,
      firstname: user.firstname,
      lastname: user.lastname,
      password: user.password
    }
    if (user.firstname.trim().length === 0) {
      alert("First name is required!");
      return;
    }
    if (user.lastname.trim().length === 0) {
      alert("Last name is required!");
      return;
    }
    if (user.password.trim().length === 0) {
      alert("Password is required!");
      return;
    }
    if (user.password.trim() != user.confirm_password.trim()) {
      alert("Retype password doesn't match!");
      return;
    }
    // printOut(TAG, "onSubmit", params);
    this.props.onConfirm(params);
  }

  render() {
    var { user } = this.props;
    var msgsForm;
    if (user && user.message && user.message.length > 0) {
      msgsForm = user.message.map((msg, index) => {
        if (user.success)
          return <InfoItem key={index} msg={msg} />
        else
          return <ErrorItem key={index} msg={msg} />
      });
    }

    if (user.success) {
      return <Redirect to='/welcome' />
    }

    return (
      <React.Fragment>
        <div className="app-blank d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
            <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
              <div className="d-flex flex-stack py-2">
                <div className="me-2">
                  <a href="/" className="btn btn-icon bg-light rounded-circle">
                    <i className="ki-duotone ki-black-left fs-2 text-gray-800"></i>
                  </a>
                </div>
                <div className="m-0">
                  <span className="text-gray-400 fw-bold fs-5 me-2" data-kt-translate="sign-up-head-desc">Already a member ?</span>
                  <a href="/users/sign-in" className="link-primary fw-bold fs-5" data-kt-translate="sign-up-head-link">Sign In</a>
                </div>
              </div>
              <div className="py-20">
                <form className="form w-100" noValidate="noValidate" id="kt_sign_up_form">
                  <div className="text-start mb-10">
                    <h1 className="text-dark mb-3 fs-3x" data-kt-translate="sign-up-title">Create an Account</h1>
                  </div>
                  <div className="row fv-row mb-7">
                    <div className="col-xl-6">
                      <input className="form-control form-control-lg form-control-solid" type="text" placeholder="First Name" name="firstname" disabled value={user.firstname} onChange={this.onChange} autoComplete="off" data-kt-translate="sign-up-input-first-name" />
                    </div>
                    <div className="col-xl-6">
                      <input className="form-control form-control-lg form-control-solid" type="text" placeholder="Last Name" name="lastname" disabled value={user.lastname} onChange={this.onChange} autoComplete="off" data-kt-translate="sign-up-input-last-name" />
                    </div>
                  </div>
                  <div className="fv-row mb-7">
                    <input className="form-control form-control-lg form-control-solid" type="email" placeholder="Email" name="email" disabled value={user.email} onChange={this.onChange} autoComplete="off" data-kt-translate="sign-up-input-email" />
                  </div>
                  <div className="fv-row mb-7" data-kt-password-meter="true">
                    <div className="mb-1">
                      <div className="position-relative mb-3">
                        <input className="form-control form-control-lg form-control-solid" type="password" value={user.password} onChange={this.onChange}
                          placeholder="Password" name="password" autocomplete="off"
                          data-kt-translate="sign-up-input-password" />
                        <div className="text-muted" data-kt-translate="sign-up-hint">Use 8 or more characters with a
                          mix of letters, numbers & symbols.</div>
                      </div>
                    </div>
                  </div>
                  <div className="fv-row mb-7">
                    <input className="form-control form-control-lg form-control-solid" type="password" value={user.confirm_password} onChange={this.onChange}
                      placeholder="Confirm Password" name="confirm_password" autocomplete="off"
                      data-kt-translate="sign-up-input-confirm-password" />
                  </div>
                  {msgsForm}
                  <div className="d-flex flex-stack">
                    <button id="kt_sign_up_submit" className="btn btn-primary" type="button" onClick={this.onSubmit}>
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="m-0">
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    index: state.indexReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onConfirm: (user) => {
      dispatch(showProgress());
      dispatch(confirm(user));
    },
    onTypeInRegisterForm: (data) => {
      dispatch(typeInRegisterForm(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpConfirm);
