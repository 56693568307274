import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showProgress } from './../actions/index';
import Pagination from 'react-js-pagination';
import * as Config from './../constants/Config';
import * as turf from '@turf/turf'
import { Link } from 'react-router-dom';
// import { useHistory } from "react-router-dom";

class ApiHub extends Component {
  constructor(props) {
    super(props);
  }

  // componentWillMount() {
  //   console.log("componentWillMount");
  // }

  componentDidMount() {
    // console.log("componentDidMount");
  }

  onShowModal = () => {
    // console.log("onShowModal");
    this.props.onShowHideModal(true);
  }

  onHideModal = () => {
    // console.log("onHideModal");
    this.props.onShowHideModal(false);
  }

  render() {
    return (
      <React.Fragment>
        <div className="d-flex flex-column flex-column-fluid my-10">
          {/* <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1
                  className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                  API Hub</h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <a href="/"
                      className="text-muted text-hover-primary">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"></span>
                  </li>
                  <li className="breadcrumb-item text-muted">API Hub</li>
                </ul>
              </div>
            </div>
          </div> */}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-xxl">
              <div className="card" id="kt_pricing">
                <div className="card-body p-lg-17">
                  <div className="d-flex flex-column">
                    <div className="mb-7 text-center">
                      <h1 className="fs-2hx fw-bold mb-5">API Collection</h1>
                    </div>
                    <div className="tab-content mb-7" id="tabContent">
                      <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                        <div className="row g-10">
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">Finance</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Yahoo Finance</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Bloomberg</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Morning Star</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Seeking Alpha</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">CNBC</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Investing Crypto</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Trading View</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Webull</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Schwab</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Fidelity</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                </div>
                                <Link to="apis/2c57f1d1-bb20-430b-97f1-d1bb20d30b54" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">E-commerce</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Asos</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Forever21</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Zappos</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Target</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Sephora</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Kohls</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Walmart</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">H&M</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">SHEIN</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Wayfair</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                </div>
                                <Link to="apis/65b7c1b4-ecc9-4feb-b7c1-b4ecc96feb56" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">Real Estate</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Realtor</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Realtor (CA)</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Idealista</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Zoopla</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Real Estate (AU)</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Bayut</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Redfin</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                </div>
                                <Link to="apis/b57f2649-5685-470b-bf26-495685370ba5" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">Sport</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Livescore</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Transfermarkt</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Cricbuzz</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Sofascore</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                </div>
                                <Link to="apis/746c2af9-7cef-4694-ac2a-f97cefe694b4" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">Entertaiment</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">IMDb</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Flixster<br />Rotten Tomatoes</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Webtoon</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                </div>
                                <Link to="apis/a79bec3a-feb3-40d6-9bec-3afeb350d6e5" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">Transportation</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">17track</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Trackingmore</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">FlightRadar24</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                </div>
                                <Link to="apis/f0781740-d3b3-4d3f-b817-40d3b3dd3fe7" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">Traveling</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">TripAdvisor</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Booking</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Hotels</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">TheFork</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                </div>
                                <Link to="apis/cb6f2cb8-7912-4898-af2c-b87912e898ab" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">Reviews</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Consumer Reports</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Trust Pilot</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3"> </span>
                                  </div>
                                </div>
                                <Link to="apis/2708539f-d154-423a-8853-9fd154f23a4d" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">AI & ML</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Shazam</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Photomath</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                </div>
                                <Link to="apis/4cb3b9a1-9b70-458c-b3b9-a19b70b58cd6" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex h-100 align-items-top">
                              <div
                                className="w-100 d-flex flex-column flex-top rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-bolder">Weather</h1>
                                  <div className="text-gray-400 fw-semibold mb-5">including</div>
                                </div>
                                <div className="w-100 mb-10">
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">The Weather</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                  <div className="d-flex align-items-center mb-5">
                                    <span
                                      className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Air Visual</span>
                                    <i className="ki-duotone ki-check-circle fs-1 text-success">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                  </div>
                                </div>
                                <Link to="apis/f93e018f-80a5-43fb-be01-8f80a513fb75" className="btn btn-sm btn-primary">Select</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h3 className="text-gray-800 w-bolder mb-4">Frequently Asked Questions</h3>
                    <div className="m-0">
                      <div className="d-flex align-items-center collapsible py-3 toggle mb-0"
                        data-bs-toggle="collapse" data-bs-target="#kt_job_8_1">
                        <div
                          className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                          <i
                            className="ki-duotone ki-minus-square toggle-on text-primary fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <i
                            className="ki-duotone ki-plus-square toggle-off fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                          </i>
                        </div>
                        <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                          Is my payment information secure?</h4>
                      </div>
                      <div id="kt_job_8_1" className="collapse show fs-6 ms-1">
                        <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                          Credit cards are processed through a PCI compliant banking partner.</div>
                      </div>
                      <div className="separator separator-dashed"></div>
                    </div>
                    <div className="m-0">
                      <div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                        data-bs-toggle="collapse" data-bs-target="#kt_job_8_2">
                        <div
                          className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                          <i
                            className="ki-duotone ki-minus-square toggle-on text-primary fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <i
                            className="ki-duotone ki-plus-square toggle-off fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                          </i>
                        </div>
                        <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">When will I be billed?</h4>
                      </div>
                      <div id="kt_job_8_2" className="collapse fs-6 ms-1">
                        <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                          We charge your credit card upon subscription to an API's plan and at the next recurring interval.</div>
                      </div>
                      <div className="separator separator-dashed"></div>
                    </div>
                    <div className="m-0">
                      <div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                        data-bs-toggle="collapse" data-bs-target="#kt_job_8_3">
                        <div
                          className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                          <i
                            className="ki-duotone ki-minus-square toggle-on text-primary fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <i
                            className="ki-duotone ki-plus-square toggle-off fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                          </i>
                        </div>
                        <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                          How are refunds handled?</h4>
                      </div>
                      <div id="kt_job_8_3" className="collapse fs-6 ms-1">
                        <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                          For refund requests, please contact us at apidojo@gmail.com</div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    map: state.mapReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onTypeInSearchForm: (data) => {
      dispatch(typeInSearchForm(data));
    },
    onShowHideModal: (state) => {
      dispatch(showHideModal(state));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiHub);
