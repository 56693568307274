import * as types from './../constants/ActionTypes';
import * as apiCaller from './../utils/apiCaller';
import { handleError, hideProgress } from './index';

export function listSubscription(params) {
  return dispatch => apiCaller.callApiWithToken('subscriptions/list', 'GET', null, params).then((resp) => {
    var { data } = resp;
    if (data.success === true) {
      dispatch(listSubscriptionSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function listSubscriptionSuccess(data) {
  return { data, type: types.SUBSCRIPTION_LIST_COMPLETED };
}

export function createSubscription(params) {
  return dispatch => apiCaller.callApiWithToken('subscriptions/create', 'GET', null, params).then((resp) => {
    var { data } = resp;
    if (data.success === true) {
      dispatch(createSubscriptionSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function createSubscriptionSuccess(data) {
  return { data, type: types.SUBSCRIPTION_CREATE_COMPLETED };
}

export function getSubscriptionDetail(params) {
  return dispatch => apiCaller.callApiWithToken('subscriptions/detail', 'GET', null, params).then((resp) => {
    var { data } = resp;
    if (data.success === true) {
      dispatch(getSubscriptionDetailSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function getSubscriptionDetailSuccess(data) {
  return { data, type: types.SUBSCRIPTION_GET_COMPLETED };
}

export function renewKey(params) {
  return dispatch => apiCaller.callApiWithToken('subscriptions/renew-key', 'GET', null, params).then((resp) => {
    var { data } = resp;
    if (data.success === true) {
      dispatch(renewKeySuccess(data));
      dispatch(listSubscription(params));
      dispatch(getSubscriptionDetail(params));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function renewKeySuccess(data) {
  return { data, type: types.SUBSCRIPTION_RENEW_KEY_COMPLETED };
}

export const choosePlan = (plan) => {
  return {
    plan,
    type: types.PLAN_CHOSEN
  };
};

export const back = () => {
  return {
    type: types.SUBSCRIPTION_BACK
  };
};

export const next = () => {
  return {
    type: types.SUBSCRIPTION_NEXT
  };
};