import * as types from './../constants/ActionTypes';
import { printOut } from '../actions';

var initialState = {
    success: false,
    card_id: 0,
    message: [],
    data: []
};

const TAG = "planReducer";

const planReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.PLAN_LIST_COMPLETED:
            //console.log('planReducer ',action);
            state = {
                ...state,
                ...action.data
            }
            return state;
        default:
            if (action && action.data && action.data.success == true) {
                state = {
                    ...state,
                    isLoading: false,
                    message: []
                }
            }
            else if (action && action.data && action.data.success == false && action.data.message) {
                state = {
                    ...state,
                    isLoading: false,
                    message: action.data.message
                }
            }
            return state;
    }
};

export default planReducer;