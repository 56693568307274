import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Terms extends Component {
  constructor(props) {
    super(props);
  }

  // componentWillMount() {
  //   console.log("componentWillMount");
  // }

  componentDidMount() {
    // console.log("componentDidMount");
  }

  onShowModal = () => {
    // console.log("onShowModal");
    this.props.onShowHideModal(true);
  }

  onHideModal = () => {
    // console.log("onHideModal");
    this.props.onShowHideModal(false);
  }

  render() {
    return (
      <React.Fragment>
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                  {/* <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1
                      className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                      Terms & Conditions</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                      <li className="breadcrumb-item text-muted">
                        <a href="#"
                          className="text-muted text-hover-primary">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                      </li>
                      <li className="breadcrumb-item text-muted">Terms & Conditions</li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div id="kt_app_content" className="app-content flex-column-fluid mb-10">
                <div id="kt_app_content_container" className="app-container container-xxl">
                  <div className="card" id="kt_pricing">
                    <div className="card-body p-lg-17">
                      <div className="d-flex flex-column">
                        <h4 className="text-gray-800 w-bolder mb-4">See also: APIDojo’s <Link to="/privacy">Privacy Policy</Link></h4>
                        <h3 className="text-gray-800 w-bolder mb-4">Welcome to APIDojo.net (the “Site”), a website operated by APIDojo (the "Team").</h3>
                        <div className="m-0">
                        <p className="fw fs-4 pt-5">
                          Using the Site, you can sign up for a service that autonomously understands any webpage and converts it into a set of entities and facts (the “Service”).
                          </p>
                          <p className="fw fs-4 pt-5">
                          The following Terms of Use (“Terms”) apply when you use the Site and Service.
                          </p>
                          <p className="fw fs-4 pt-5">
                          Please review these Terms carefully. By accessing or using the Site or Service, or by clicking “I agree” when you create an account, you show you agree to these Terms. If you don’t agree to these Terms, you may not access or use the Site or Service.
                          </p>
                          <p className="fw fs-4 pt-5">
                          ABOUT THE SITE AND SERVICE You will be charged for access to the Service based on the plan you pick when you create an account.
                          </p>
                          <p className="fw fs-4 pt-5">
                          You will be required to provide the Team with information regarding your credit card or other payment method. You represent and warrant to the Team that such information is true and that you are authorized to use the payment method. You will promptly update your account information with any changes (for example, a change in your billing address or credit card expiration date). You hereby authorize the Team to bill you in advance on a periodic basis in accordance with the terms of your plan until you terminate your account, and you agree to pay any charges so incurred. If you dispute any charges you must notify the Team within thirty (30) days after the date that you are billed.
                          </p>
                          <p className="fw fs-4 pt-5">
                          We reserve the right to change the Team’s fees. If the Team does change its fees, the Team will provide notice of the change on the Site or in email to you, at Team’s option, at least 14 days before the change is to take effect. Your continued use of the Service after the fee change becomes effective constitutes your agreement to pay the changed amount.
                          </p>
                          <p className="fw fs-4 pt-5">
                          We may terminate your access to the Service for any reason, at our sole discretion
                          </p>
                          <p className="fw fs-4 pt-5">
                          You can terminate your use of the Service at any time. NO REFUNDS WILL BE ISSUED.
                          </p>
                          <p className="fw fs-4 pt-5">
                          The Site and Service are under constant development. New features may be added frequently, so it’s important to check back to this page for updates.
                          </p>
                          <p className="fw fs-4 pt-5">
                          USE OF THE SITE AND SERVICE You need to be at least 13 years of age to use the Site and Service.
                          </p>
                          <p className="fw fs-4 pt-5">
                          You are responsible for maintaining the confidentiality of your login, password, and account and for all activities that occur under your login or account.
                          </p>
                          <p className="fw fs-4 pt-5">
                          The Team does not want to receive confidential or proprietary information from you through the Site or Service. Any information or material sent to the Team will be deemed NOT to be confidential. By sending the Team any information or other material, you grant Team a non-exclusive, unrestricted, irrevocable, perpetual, royalty-free, worldwide, assignable, transferrable, sublicenseable right and license to use, reproduce, display, transmit, translate, rent, sell, modify, disclose, publish, create derivative works from and distribute that material or information.
                          </p>
                          <p className="fw fs-4 pt-5">
                          You agree that the Team is free to use any ideas, concepts, know-how or techniques that you send us for any purpose, without compensation or attribution.
                          </p>
                          <p className="fw fs-4 pt-5">
                            Our service provides live data extraction. The system does not store or save data locally, the system provides processes helping you to extract data in real time and on demand, at the time you making the requests, you are charged for processes by requests.
                          </p>
                          <p className="fw fs-4 pt-5">
                            USE RESTRICTIONS Your permission to use the Service is conditioned upon the following restrictions and conditions.
                          </p>
                          <p className="fw fs-4 pt-5">
                            You agree that you will not:
                          </p>
                          <p className="fw fs-4 pt-5">
                            • sublicense, resell, rent, lease, transfer, assign, time share, or otherwise commercially exploit or make the Service available to any third party (However, you may display and use the facts generated by the Service as part of a commercial application.)
                          </p>
                          <p className="fw fs-4 pt-5">
                            • use the Service for any unlawful purpose or for the promotion of illegal activities;
                          </p>
                          <p className="fw fs-4 pt-5">
                            • use the Service to attempt to, or harass, abuse or harm another person or group;
                          </p>
                          <p className="fw fs-4 pt-5">
                            • interfere or attempt to interfere with the proper functioning of the Service;
                          </p>
                          <p className="fw fs-4 pt-5">
                            • modify, adapt or hack the Service to, or otherwise attempt to gain unauthorized access to the Service or its related systems or networks;
                          </p>
                          <p className="fw fs-4 pt-5">
                            • use the API or the Data in any manner that violates the rights of any person, including but not limited to intellectual property rights, rights of privacy or rights of publicity;
                          </p>
                          <p className="fw fs-4 pt-5">
                            • reverse engineer, decompile or disassemble any portion of the Service;
                          </p>
                          <p className="fw fs-4 pt-5">
                            • bypass any robot exclusion headers or other measures we take to restrict access to Service;
                          </p>
                          <p className="fw fs-4 pt-5">
                            • publish or link to malicious content intended to damage or disrupt another user’s browser, computer, or mobile device.
                          </p>
                          <p className="fw fs-4 pt-5">
                            INTELLECTUAL PROPERTY You acknowledge and agree that the Team retain ownership of all intellectual property rights of any kind related to the Site and Service (except for user content, third-party content, and public domain content), including applicable copyrights, trademarks, and other proprietary rights. The Team reserves all rights that are not expressly granted to you under these Terms.
                          </p>
                          <p className="fw fs-4 pt-5">
                            You hereby grant, and you represent and warrant that you have the right to grant, to APIDojo an irrevocable, perpetual, nonexclusive, royalty-free and fully paid, worldwide license to “process” (meaning to perform any activity, including reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, disclose, and otherwise use) your user-submitted queries or data enhance requests.
                          </p>
                          <p className="fw fs-4 pt-5">
                            INDEMNIFICATION You agree to indemnify, defend, and hold harmless the Team from any and all claims, liabilities, expenses, and damages, including reasonable attorneys’ fees and costs, made by any third party related to: (a) your use or attempted use of the Site or Services in violation of these Terms; (b) your violation of any law or rights of any third party.
                          </p>
                          <p className="fw fs-4 pt-5">
                            DISCLAIMER The Team does not guarantee the accuracy, completeness, or usefulness of any information on the Site or conveyed using the Service. Under no circumstances will the Team be responsible for any loss or damage resulting from anyone’s reliance on information or other content posted on the Site or created using the Service.
                          </p>
                          <p className="fw fs-4 pt-5">
                            USER CONSENT TO RECEIVE COMMUNICATIONS IN ELECTRONIC FORM For contractual purposes, you (a) consent to receive emails via the email address you provide when you sign up for the Service; and (b) agree that all Terms, agreements, notices, disclosures, and other communications that the Team provides to you electronically satisfy any legal requirement that such communications would satisfy if it were in writing. The foregoing does not affect your non-waivable rights.
                          </p>
                          <p className="fw fs-4 pt-5">
                            The Team may also use your email address to send you other messages, including information about the Team, Site, and Service and special offers. You may opt out of such email by changing your account settings or sending an email to apidojo@gmail.com.
                          </p>
                          <p className="fw fs-4 pt-5">
                            WARRANTY DISCLAIMER THE SITE AND SERVICE ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, THE TEAM EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, REGARDING THE SITE INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, THE TEAM MAKES NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SITE OR SERVICE WILL BE UNINTERRUPTED OR ERROR FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR UPLOADING, DOWNLOADING, AND/OR USE OF FILES, INFORMATION, CONTENT OR OTHER MATERIAL SENT TO OR OBTAINED FROM THE SITE OR SERVICE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF WARRANTY, SO THIS PROVISION MAY NOT APPLY TO YOU.
                          </p>
                          <p className="fw fs-4 pt-5">
                            LIMITATION OF DAMAGES; RELEASE TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE TEAM, ITS AFFILIATES, DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE, OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM (A) THE USE, DISCLOSURE, OR DISPLAY OF YOUR USER CONTENT; (B) YOUR USE OR INABILITY TO USE THE SITE OR SERVICE; (C) THE SITE AND SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SITE AND SERVICE AVAILABLE; OR (D) ANY OTHER INTERACTIONS WITH THE TEAM OR ANY OTHER USER OF THE SITE OR SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), FRAUD, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE TEAM HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL TEAM’S LIABILITY TO YOU EXCEED $100. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU.
                          </p>
                          <p className="fw fs-4 pt-5">
                            MODIFICATION OF TERMS OF USE The Team can amend these Terms at any time. It’s your responsibility to check the Site from time to time to view any such changes. If you continue to use the Site or Service, you show your agreement to our revisions to these Terms. Any changes to these Terms (other than as set forth in this paragraph) or waiver of the Team’s rights hereunder shall not be valid or effective except in a written agreement bearing the physical signature of an officer of the Team. No purported waiver or modification of these Terms by the Team via telephonic or email communications shall be valid.
                          </p>
                          <p className="fw fs-4 pt-5">
                            GENERAL TERMS If any part of these Terms is held invalid or unenforceable, that portion of the Terms will be construed consistent with applicable law. The remaining portions will remain in full force and effect. Any failure on the part of the Team to enforce any provision of these Terms will not be considered a waiver of our right to enforce such provision. Our rights under these Terms will survive any termination of these Terms.
                          </p>
                          <p className="fw fs-4 pt-5">
                            You agree that any cause of action related to or arising out of your relationship with the Team must commence within ONE year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
                          </p>
                          <p className="fw fs-4 pt-5">
                            If you attempt to bring any legal action against the Team based in any way on its Site or Service you agree that, in the event you do not prevail or the Team does prevail, you will reimburse the Team for any costs and attorneys’ fees associated with its defense of the action.
                          </p>
                          <p className="fw fs-4 pt-5">
                            The Team may assign or delegate these Terms and/or the Team’s Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms or Privacy Policy without the Team’s prior written consent, and any unauthorized assignment and delegation by you is void.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    map: state.mapReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onTypeInSearchForm: (data) => {
      dispatch(typeInSearchForm(data));
    },
    onShowHideModal: (state) => {
      dispatch(showHideModal(state));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
