import * as types from './../constants/ActionTypes';
import * as apiCaller from './../utils/apiCaller';
import { handleError, hideProgress } from './index';

// APP
export function listPlan(params) {
  return dispatch => apiCaller.callApiWithoutToken('plans/list', 'GET', null, params).then((resp) => {
    // console.log('listPlan',resp.data);
    var { data } = resp;
    if (data.success === true) {
      dispatch(listPlanSuccess(data));
    }
    else {
      dispatch(handleError(data));
    }
    dispatch(hideProgress());
  });
}

export function listPlanSuccess(data) {
  return { data, type: types.PLAN_LIST_COMPLETED };
}