import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDetail, login, typeInLoginForm } from './../actions/userAction';
import { printOut, showProgress } from './../actions/index';
import { Link, Redirect } from 'react-router-dom';
import InfoItem from './InfoItem';
import ErrorItem from './ErrorItem';

const TAG = "SignIn";

class SignIn extends Component {
  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
    this.props.onGetDetail();
  }

  onChange = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    var { user } = this.props;
    var newData = {
      ...user,
      [name]: value
    }
    // printOut(TAG,'onChange', user);
    this.props.onTypeInLoginForm(newData);
  }

  onSubmit = (e) => {
    // e.preventDefault();
    var { user } = this.props;
    // printOut(TAG,'onSubmit', user);
    if (user.email.trim().length === 0) {
      alert("Email is required!");
      return;
    }
    if (user.password.trim().length === 0) {
      alert("Password is required!");
      return;
    }
    let params = {
      email: user.email,
      password: user.password
    }
    this.props.onLogin(params);
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onSubmit(e);
    }
  }

  render() {
    var { user } = this.props;
    // printOut(TAG, "user", user);
    var msgsForm;
    if (user && user.message && user.message.length > 0) {
      msgsForm = user.message.map((msg, index) => {
        if (!user.success)
          return <ErrorItem key={index} msg={msg} />
      });
    }

    // var path = window.location.pathname;
    if (user.authorizedStatus == 1) {
      if (user && user.last_visit_url && user.last_visit_url != "/users/sign-in" && user.last_visit_url != "/users/sign-up") {
        return <Redirect to={user.last_visit_url} />
      }
      else {
        return <Redirect to={"/dashboard"} />
      }
    }

    return (
      <React.Fragment>
        <div className="app-blank d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
            <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
              <div className="d-flex flex-stack py-2">
                <div className="me-2">
                  <Link to="/" className="btn btn-icon bg-light rounded-circle">
                    <i className="ki-duotone ki-black-left fs-2 text-gray-800"></i>
                  </Link>
                </div>
                <div className="m-0">
                  <span className="text-gray-400 fw-bold fs-5 me-2" data-kt-translate="sign-in-head-desc">Not a
                    Member yet?</span>
                  <Link to="/users/sign-up" className="link-primary fw-bold fs-5" data-kt-translate="sign-in-head-link">Sign Up</Link>
                </div>
              </div>
              <div className="py-20">
                {/* <form className="form w-100" id="kt_sign_in_form"
                  data-kt-redirect-url="#" action="#"> */}
                  <div className="card-body">

                    <div className="text-start mb-10">
                      <h1 className="text-dark mb-3 fs-3x" data-kt-translate="sign-in-title">Sign In</h1>
                    </div>
                    <div className="fv-row mb-8">
                      <input type="text" placeholder="Email" name="email" autoComplete="off"
                        data-kt-translate="sign-in-input-email" onKeyDown={this._handleKeyDown} 
                        className="form-control form-control-solid" value={user.email} onChange={this.onChange} />
                    </div>
                    <div className="fv-row mb-7">
                      <input type="password" placeholder="Password" name="password" autoComplete="off"
                        data-kt-translate="sign-in-input-password" onKeyDown={this._handleKeyDown} 
                        className="form-control form-control-solid" value={user.password} onChange={this.onChange} />
                    </div>
                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10">
                      <div></div>
                      <Link to="/users/reset-password" className="link-primary" data-kt-translate="sign-in-forgot-password">
                        Forgot Password ?
                      </Link>
                    </div>
                    {msgsForm}
                    <div className="d-flex flex-stack">
                      <button id="kt_sign_in_submit" className="btn btn-primary me-2 flex-shrink-0" type='button' onClick={this.onSubmit}>
                        <span className="indicator-label" data-kt-translate="sign-in-submit">Sign In</span>
                        <span className="indicator-progress">
                          <span data-kt-translate="general-progress">Please wait...</span>
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                {/* </form> */}
              </div>
              <div className="m-0">
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    index:state.indexReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetDetail: () => {
      dispatch(showProgress());
      dispatch(getDetail());
    },
    onLogin: (user) => {
      dispatch(showProgress());
      dispatch(login(user));
    },
    onTypeInLoginForm: (data) => {
      dispatch(typeInLoginForm(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);