import React, { Component } from 'react';
import { connect } from 'react-redux';
import { printOut, showProgress } from './../actions/index';
import { changePaymentMethod, getInvoiceDetail, resetInvoiceInfo, updateInvoicePayment } from '../actions/invoiceAction';
import { Redirect,Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ErrorItem from './ErrorItem';
// import invoiceReducer from '../reducers/invoiceReducer';

const TAG = "Invoice";

class Invoice extends Component {
  componentDidMount() {
    // console.log('this.props.match.params.id', this.props.match.params.id);
    var params = {
      invoice: this.props.match.params.id
    }
    this.props.onGetInvoiceDetail(params);
  }

  componentWillUnmount() {
    // printOut(TAG, 'componentWillUnmount', "");
    this.props.onResetInvoiceInfo();
  }

  downloadDocument() {
    // printOut(TAG, 'downloadDocument', "");
    const input = document.querySelector("#invoiceCard");//document.getElementById('invoiceCard');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 3, 10);
        // pdf.output('dataurlnewwindow');
        pdf.save((this.props.match.params.id?this.props.match.params.id:"download_invoice")+".pdf");
      });
  }

  onPay = () => {
    // printOut(TAG, "onPay", subscription);
    var { invoiceList } = this.props;
    if (invoiceList && invoiceList.invoice && invoiceList.invoice.paymentLink) {
      window.location.replace(invoiceList.invoice.paymentLink);
    }
    else {
      // printOut(TAG, "invoiceList.paymentMethod", invoiceList.paymentMethod);
      if (!invoiceList.paymentMethod || invoiceList.paymentMethod == "") {
        alert('Please choose a payment method!');
        return;
      }
      // Call update-paymen endpoint to generate payment link , then redirect user to the link
      var params = {
        invoice: invoiceList.invoice.id,
        paymentMethod: invoiceList.paymentMethod
      }
      // printOut(TAG, "onPay", params);
      this.props.onUpdateInvoicePayment(params);
    }
  }

  onChangePaymentMethod = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    var { invoiceList } = this.props;
    var invoice = {
      ...invoiceList,
      [name]: value
    }
    // printOut(TAG, "onChangePaymentMethod", invoice);
    this.props.onChangePaymentMethod(invoice);
  }

  render() {
    var msgsForm;
    var { invoiceList,user } = this.props;
    if (user) {
      if (user.authorizedStatus == 0) {
        // printOut(TAG,'redirect',user.last_visit_url);
        return <Redirect to="/users/sign-in" />
      }
    }
    // printOut(TAG, 'paymentLink', invoiceList);
    if (invoiceList.willRedirect == true && invoiceList && invoiceList.invoice && invoiceList.invoice.paymentLink) {
      window.location.replace(invoiceList.invoice.paymentLink);
    }
    
    if (invoiceList && invoiceList.message && invoiceList.message.length > 0) {
      msgsForm = invoiceList.message.map((msg, index) => {
        if (!invoiceList.success)
          return <ErrorItem key={index} msg={msg} />
      });
    }
    // printOut(TAG, "invoice", invoiceList.invoice);
    var price = (invoiceList && invoiceList.invoice && invoiceList.invoice.amount) ? new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(invoiceList.invoice.amount) : "N/A";

    var paymentMethod, btnPay;
    //CREATED,UPDATED,PAID,REFUNDED,PARTIAL_REFUNDED,EXPIRED
    if (invoiceList && invoiceList.invoice && invoiceList.invoice.status === "CREATED") {
      paymentMethod = <select name='paymentMethod' value={invoiceList.paymentMethod? invoiceList.paymentMethod : ""} onChange={this.onChangePaymentMethod} className="w-150px">
        <option value="">--- Choose ---</option>
        <option value="CRYPTOMUS">Crypto</option>
        <option disabled value="card">Card (Comming soon)</option>
        <option disabled value="skrill">Skrill (Comming soon)</option>
        <option disabled value="neteller">Neteller (Comming soon)</option>
      </select>

      btnPay = <button type="button" className="btn btn-primary ms-5" onClick={this.onPay}>
        <span className="indicator-label">PAY</span>
        <span className="indicator-progress">Please wait...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
      </button>
    }
    else {
      paymentMethod = invoiceList.invoice.paymentMethod;
    }
    var today = moment();
    var fm = moment(today).add(1, 'M');
    var fmEnd = moment(fm).endOf('month');
    var aMonthLater = today.date() != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;
    return (
      <React.Fragment>
        <div className="post d-flex flex-column-fluid">
          <div className="container-md mw-850px my-5">
            <div className="card">
              <div className="card-body py-20">
                <div id="invoiceCard" className="mw-lg-950px mx-auto w-100 ps-5 pe-5">
                  <div className="d-flex justify-content-between flex-column flex-sm-row mb-10">
                    <h4 className="fw-boldest text-gray-800 fs-2qx pe-5 pb-7">INVOICE</h4>
                    <div className="text-sm-end">
                      <a href="#" className="d-block mw-150px ms-sm-auto">
                        <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                        <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-dark-show" />
                      </a>
                      {/* <div className="text-sm-end fw-bold fs-4 text-muted mt-7">
                        <div>Cecilia Chapman, 711-2880 Nulla St, Mankato</div>
                        <div>Mississippi 96522</div>
                      </div> */}
                    </div>
                  </div>
                  <div>
                    <div className="d-flex flex-column gap-7 gap-md-10">
                      <div className="fs-4">Invoice number <span className="fw-bolder fs-4">{(invoiceList && invoiceList.invoice && invoiceList.invoice.id) ? invoiceList.invoice.id : "N/A"}</span><br />
                      </div>
                      <div className="separator"></div>
                      <table>
                        <thead>
                          <tr>
                            <th className="min-w-100px">Invoice date</th>
                            <th className="min-w-100px">Due date</th>
                            <th className="min-w-100px">Payment method</th>
                            <th className="min-w-100px">Payment status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="fs-5 fw-bolder">{(invoiceList && invoiceList.invoice && invoiceList.invoice.createdAt) ? invoiceList.invoice.createdAt.substring(0, invoiceList.invoice.createdAt.indexOf("T")) : "N/A"}</td>
                            <td className="fs-5 fw-bolder">{(invoiceList && invoiceList.invoice && invoiceList.invoice.expiredAt) ? invoiceList.invoice.expiredAt.substring(0, invoiceList.invoice.expiredAt.indexOf("T")) : "N/A"}</td>
                            <td className="fs-5 fw-bolder ">
                              {paymentMethod}
                            </td>
                            <td className="fs-5 fw-bolder">{(invoiceList && invoiceList.invoice && invoiceList.invoice.status) ? invoiceList.invoice.status : "N/A"}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10">
                        <div className="flex-root d-flex flex-column">
                          <span className="fs-6 fw-bolder">Pay to</span>
                          <span>API Dojo
                            {/* <br />Unit 1/23 Hastings Road,
                            <br />Melbourne 3000,
                            <br />Victoria,
                            <br />Australia. */}
                          </span>
                        </div>
                        <div className="flex-root d-flex flex-column">
                          <span className="fs-6 fw-bolder">Bill to</span>
                          <span>{(invoiceList && invoiceList.invoice && invoiceList.invoice.userEmail) ? invoiceList.invoice.userEmail : "N/A"}</span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-column">
                        <div className="table-responsive border-bottom mb-9">
                          <table
                            className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                            <thead>
                              <tr className="border-bottom fs-6">
                                <th className="min-w-175px pb-2">Description</th>
                                <th className="min-w-70px text-end pb-2">Qty</th>
                                <th className="min-w-80px text-end pb-2">Unit</th>
                                <th className="min-w-100px text-end pb-2">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <div className="fw-bolder">{(invoiceList && invoiceList.invoice && invoiceList.invoice.planName) ? invoiceList.invoice.planName : "N/A"}</div>
                                      <div className="fs-7">Period : {(invoiceList && invoiceList.invoice && invoiceList.invoice.periodStart) ? invoiceList.invoice.periodStart.substring(0, invoiceList.invoice.periodStart.indexOf("T")) : "N/A"} - {(invoiceList && invoiceList.invoice && invoiceList.invoice.periodEnd) ? invoiceList.invoice.periodEnd.substring(0, invoiceList.invoice.periodEnd.indexOf("T")) : "N/A"}</div>
                                      {/* today.format('YYYY-MM-DD') aMonthLater.format('YYYY-MM-DD')*/}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end">1</td>
                                <td className="text-end">{price}</td>
                                <td className="text-end">{price}</td>
                              </tr>
                              <tr>
                                <td colSpan="3" className="text-end">Subtotal</td>
                                <td className="text-end">{price}</td>
                              </tr>
                              <tr>
                                <td colSpan="3" className="text-end">Total</td>
                                <td className="text-end">{price}</td>
                              </tr>
                              <tr>
                                <td colSpan="3"
                                  className="fs-4 fw-bolder text-end">Amount due</td>
                                <td className="fs-4 fw-bolder text-end">{price}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-print-none mb-5" data-html2canvas-ignore>
                    <div className="col-lg-6">
                      <a href="#" onClick={() => window.print()}><i className="fas fa-print"></i> Print</a>&nbsp;&nbsp;&nbsp;
                      <a href="#" onClick={() => this.downloadDocument()}><i className="fas fa-download"></i> Download</a>
                    </div>
                    <div className="col-lg-6 text-end">
                      <Link to="../invoices" className="btn btn-light">Back</Link>
                      {btnPay}
                    </div>
                  </div>
                  {msgsForm}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    invoiceList: state.invoiceReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetInvoiceDetail: (params) => {
      dispatch(showProgress());
      dispatch(getInvoiceDetail(params));
    },
    onUpdateInvoicePayment: (params) => {
      dispatch(showProgress());
      dispatch(updateInvoicePayment(params));
    },
    onChangePaymentMethod: (invoice) => {
      dispatch(changePaymentMethod(invoice));
    },
    onResetInvoiceInfo: () => {
      dispatch(resetInvoiceInfo());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);