import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, typeInLoginForm, checkToken, typeInRegisterForm, register } from './../actions/userAction';
import { printOut, showProgress } from './../actions/index';
import { NavLink, Redirect } from 'react-router-dom';
import InfoItem from './InfoItem';
import ErrorItem from './ErrorItem';

const TAG = "Error";
class Error extends Component {
  render() {
    // var { user } = this.props;
    // printOut(TAG, "render", user);
    return (
      <React.Fragment>
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-column flex-column-fluid">
            <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
              <a href="/" className="mb-10 pt-lg-10">
                <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px mb-5" />
              </a>
              <div className="pt-lg-10 mb-10">
                <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">ERROR</h1>
                <div className="fw-bold fs-3 text-muted mb-15">Something went wrong!
                  <br />Please try again later.
                </div>
                <div className="text-center">
                  <a href="/" className="btn btn-lg btn-primary fw-bolder">Go to Homepage</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onRegister: (user) => {
      dispatch(showProgress());
      dispatch(register(user));
    },
    onTypeInRegisterForm: (data) => {
      dispatch(typeInRegisterForm(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error);
