import { printOut } from '../actions';
import * as types from './../constants/ActionTypes';

var initialState = {
    success: false,
    step: 1,
    paymentMethod: "CRYPTOMUS",
    pagination: {
        current_page: 1,
        size: 10,
        total: 0
    },
    invoice: {},
    subscriptions:[],
    subscription: {},
    plan: {},
    message: [],
    data: []
};

const TAG = "subscriptionReducer";

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SUBSCRIPTION_GET_COMPLETED:
            // printOut(TAG, 'SUBSCRIPTION_GET_COMPLETED', action);
            state = {
                ...state,
                subscription: action.data.data
            }
            // printOut(TAG, 'SUBSCRIPTION_GET_COMPLETED', state);
            return state;
        case types.SUBSCRIPTION_LIST_COMPLETED:
            //printOut(TAG,'SUBSCRIPTION_LIST_COMPLETED',action);
            state = {
                ...state,
                ...action.data,
                subscriptions:action.data.data,
                pagination: action.data.metadata.pagination
            }
            return state;
        case types.SUBSCRIPTION_CREATE_COMPLETED:
            // printOut(TAG, 'SUBSCRIPTION_CREATE_COMPLETED', action);
            state = {
                ...state,
                invoice: action.data.data.invoice
            }
            // printOut(TAG, 'SUBSCRIPTION_CREATE_COMPLETED', state);
            return state;
        case types.SUBSCRIPTION_RENEW_KEY_COMPLETED:
            //printOut(TAG,'SUBSCRIPTION_RENEW_KEY_COMPLETED',action);
            state = {
                ...state,
                ...action.data
            }
            return state;
        case types.SUBSCRIPTION_BACK:
            var step = state.step;
            if (step < 1) {
                step = 1;
            } else {
                step = step - 1;
            }
            state = {
                ...state,
                step: step
            };
            // printOut(TAG,'SUBSCRIPTION_BACK',state);
            return state;
        case types.SUBSCRIPTION_NEXT:
            var step = state.step;
            if (step > 3) {
                step = 3;
            } else {
                step = step + 1;
            }
            state = {
                ...state,
                step: step
            };
            // printOut(TAG,'SUBSCRIPTION_NEXT',state);
            return state;
        case types.PLAN_CHOSEN:
            state = {
                ...state,
                plan: action.plan
            };
            // printOut(TAG, 'PLAN_CHOSEN', state);
            return state;
        default:
            if (action && action.data && action.data.success == true) {
                state = {
                    ...state,
                    isLoading: false,
                    message: []
                }
            }
            else if (action && action.data && action.data.success == false && action.data.message) {
                state = {
                    ...state,
                    isLoading: false,
                    message: action.data.message
                }
            }
            return state;
    }
};

export default subscriptionReducer;