import React, { Component } from 'react';
import { connect } from 'react-redux';

const TAG = "NotFound";
class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-column flex-center flex-column-fluid p-10">
            <img src="assets/media/illustrations/sketchy-1/18.png" alt="" className="mw-100 mb-10 h-lg-450px" />
            <h1 className="fw-bold mb-10" style={{color:'#A3A3C7'}}>Seems there is nothing here</h1>
            <a href="/" className="btn btn-primary">Return Home</a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
