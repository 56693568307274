import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Privacy extends Component {
  constructor(props) {
    super(props);
  }

  // componentWillMount() {
  //   console.log("componentWillMount");
  // }

  componentDidMount() {
    // console.log("componentDidMount");
  }

  onShowModal = () => {
    // console.log("onShowModal");
    this.props.onShowHideModal(true);
  }

  onHideModal = () => {
    // console.log("onHideModal");
    this.props.onShowHideModal(false);
  }

  render() {
    return (
      <React.Fragment>
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                  {/* <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1
                      className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                      Our Commitment to Your Privacy</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                      <li className="breadcrumb-item text-muted">
                        <a href="#"
                          className="text-muted text-hover-primary">Updated:</a>
                      </li>
                      <li className="breadcrumb-item">
                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                      </li>
                      <li className="breadcrumb-item text-muted">April 10, 2023</li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div id="kt_app_content" className="app-content flex-column-fluid mb-10">
                <div id="kt_app_content_container" className="app-container container-xxl">
                  <div className="card" id="kt_pricing">
                    <div className="card-body p-lg-17">
                      <div className="d-flex flex-column">
                        <h4 className="text-gray-800 w-bolder mb-4">See also: APIDojo’s <Link to="/terms">Terms of Use</Link></h4>
                        <div className="m-0">
                          <p className="fw fs-4 pt-5">
                            This privacy policy applies to https://apidojo.net (the Site), operated by APIDojo team. ("APIDojo", "us", "we" or "our").
                          </p>
                          <p className="fw fs-4 pt-5">
                            The Site provides the “Services” as defined in the Terms of Use. This Privacy Policy explains how we collect and use the personal data of “Visitors” to our site (people who visit our site), of “Subscribers” (users of our Services) and of “Search Subjects” (people who have published personal data about themselves to publicly available web sites and web services on the Internet which are the subject of our APIs).
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>ABOUT APIDojo</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            APIDojo is a service based on a special case that uses techniques, bots, algorithms, computer vision and artificial intelligence (AI) to process the content on the Internet thereby allowing websites to be broken down into different pages, tabs, and sections to be identified. APIDojo’s technology differentiates between a social network profile, a blog post, a website’s front page, a product page, an event page, and more. APIDojo’s techniques, machine learning and computer vision algorithms and APIs (application program interfaces) are used by Subscribers of our services to facilitate the discovery, identification, and extraction of data content from publicly accessible and available websites and services. The extracted data is always live, and synced with the sites/applications in real time. We do not store or save data locally for use in a long time.
                          </p>
                          <p className="fw fs-4 pt-5">
                            More details about the APIDojo products, and services offered can be found on our website at https://apidojo.net.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>WHAT INFORMATION DO WE COLLECT?</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            When a Visitor registers to our Service, the Visitor becomes a Subscriber of our services. We collect the following data in order to provide the services:
                            <br />
                            _ First Name
                            <br />
                            _ Last Name
                            <br />
                            _ Email Address
                            <br />
                            _ Information collected from cookies
                          </p>
                          <p className="fw fs-4 pt-5">
                            We also issue each Subscriber unique API keys in order to access the Services during the subscription period
                          </p>
                          <p className="fw fs-4 pt-5">
                            During use of the Services (for subscribed Users and Subscribers) we collect and use the following data and associate it with your API key in order to provide you with our services. This includes:
                            <br />
                            _ Information collected from cookies
                            <br />
                            _ Logins
                            <br />
                            _ Query history
                            <br />
                            _ API calls
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>VISITOR(S) and SUBSCRIBER(S):</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            We collect non-personal information (i.e. information that on its own cannot be used to personally identify you as an individual) that includes:
                            <br />
                            _ anonymous usage data
                            <br />
                            _ referring/exit pages and URLs
                            <br />
                            _ platform types
                            <br />
                            _ IP Address
                            <br />
                            _ Our Site or Service may not be available in all areas.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>SEARCH SUBJECTS:</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            We extract data from the public web to get different kinds of data, including data about Search Subjects that are publicly available. We allow our Subscribers to engage with the data supplied by Search Subjects in a strategic, meaningful, and targeted manner, to the extent it is available, and in accordance with the instructions of our Subscribers. This information includes but is not limited to:
                            <br />
                            _ Prefix
                            <br />
                            _ Name
                            <br />
                            _ Job title
                            <br />
                            _ Publicly available biographical information disclosed on webpages
                            <br />
                            _ Education History
                            <br />
                            _ Employment History
                            <br />
                            _ Work address
                            <br />
                            _ Work Telephone Number
                            <br />
                            _ Email Address
                            <br />
                            _ Public web page URLs and handles
                            <br />
                            _ Subject area expertise
                            <br />
                            We do not extract sensitive personal data about a Search Subject’s (race, ethnicity, religious or philosophical beliefs, sexual preferences or orientation, political opinions or memberships, trade union memberships, health information; genetic or biometric data) unless the Search Subject published such data to the public domain him/herself (i.e. you have made such information available to the public by writing about it or posting it on your social media profile(s) and/or website).
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>HOW IS DATA EXTRACTED?</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>SUBSCRIBERS</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            The data extracted from Subscribers (for both Trial and Ongoing usage) is provided by the subscribers at the time of sign up in order for us to provide the service. Usage data is extracted whenever Subscribers use the services on demand.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>COOKIE POLICY</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            APIDojo may collect information automatically from our Subscribers using web tracking technologies such as cookies, web beacons, pixel tags, clear GIFs, and third party tracking services in order to ensure that web applications and Services operate efficiently and to collect data related to usage of a web application or Service such as, but not limited to, the browser type, language preference, referring site, and the date and time of each visitor request (“Tracking Information”).
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>COOKIES AND TRACKING</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>TRIAL USERS and SUBSCRIBERS</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            We use both session-based and persistent cookies.
                          </p>
                          <p className="fw fs-4 pt-5">
                            Session-based cookies last only while your browser is open and are automatically deleted when you close your browser.
                          </p>
                          <p className="fw fs-4 pt-5">
                            Persistent cookies last until you or your browser delete them or until they expire. They are unique and allow us to collect site analytics and to customize a Subscriber’s experience. If you access our Site through your browser, you can manage your cookie settings.
                          </p>
                          <p className="fw fs-4 pt-5">
                            In order to collect Tracking Information, make your use of the Site and Service more efficient and responsive to your needs, APIDojo and its cookie service providers, detailed in the Cookie Policy, store cookies on your computer. APIDojo also uses cookies that are placed in web pages on the Site(s) and Services to collect information and learn about actions users take when they interact with the Site and Service.
                          </p>
                          <p className="fw fs-4 pt-5">
                            APIDojo does not link Tracking Information to individual user Personal Information; nor does it include the Personal Information with the Tracking Information that APIDojo shares with the web tracking companies that use and process the Tracking Information, except as strictly necessary to provide and improve the Services (including customer support services). Some Tracking Information may include log or other data, such as IP address data, that is unique to you. You may be able to modify your browser settings to alter which web tracking technologies are permitted when you use the Site(s) and Services, but this may affect the performance of the Sites and Services.
                          </p>
                          <p className="fw fs-4 pt-5">
                            If you do not wish to receive cookies, you may deactivate storing cookies on your computer by changing your browser settings accordingly. Please note that the functionality of the Site(s) and Services may be impaired, and the range of functionalities may be severely limited if you deactivate cookies.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>SEARCH SUBJECT(S)</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            The information we hold about Individuals is obtained from extracting publicly available sources on the Internet. This includes publicly available social media profiles and websites; information from articles the Search Subject may have written or was written about the Search Subject on the internet. This information is gathered by our technology using automation and machine learning algorithms.
                          </p>
                          <p className="fw fs-4 pt-5">
                            We extract data directly from publicly available sources, including but not limited to:
                          </p>
                          <p className="fw fs-4 pt-5">
                            _ Articles or blogs you may have written or may have written about you in the public domain
                          </p>
                          <p className="fw fs-4 pt-5">
                            _ Vlogs you may have created in the public domain
                          </p>
                          <p className="fw fs-4 pt-5">
                            _ Your own website
                          </p>
                          <p className="fw fs-4 pt-5">
                            _ Third-party website(s)
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>HOW DO WE USE YOUR DATA? TO PROVIDE THE SERVICES</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>SUBSCRIBER(S)</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            Subscriber data is used to provide the Service; process Service payments; facilitate support queries and requests. A Subscriber’s failure to provide the personal data we need, may result in our inability to complete the transaction or provide the service.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>SEARCH SUBJECT(S)</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            We do not control what information is collected by Subscribers or their purpose for use. To the extent any notices or consents are required, Subscribers are solely responsible for giving such notices or obtaining such consents. We do not use your personal data for activities where we believe that your interests are overridden by any unwarranted adverse impact on you.
                          </p>
                          <p className="fw fs-4 pt-5">
                            APIDojo’s primary role is to discover, categorize, and organize information on the Internet on behalf of, and as directed by, our Subscriber(s). APIDojo subscribers can use our service and query functionality to create their own lists of Search Subjects; add their own data about Search Subjects (which only they can access); and monitor Search Subjects.
                          </p>
                          <p className="fw fs-4 pt-5">
                            We allow our Subscribers access to search results via our online platform(s) and/or API service(s) to enable our subscribers to view information the Subscribers may be interested in.
                          </p>
                          <p className="fw fs-4 pt-5">
                            A Subscriber can submit a query using the API to access data collected and indexed from the Internet. APIDojo then returns a list of items or subjects and a custom data set is created (a list of entities, related entities and facts) that matches the Subscriber’s search criteria. A Subscriber may also define the data to extract from a specific web page, a group of pages or domain by accessing a suite of extraction APIs, by extending an existing extraction API, or by creating a new API using custom rules which yields extracted data.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>LEGITIMATE INTERESTS</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            Aggregation of Search Subject data is beneficial to you because it will allow the Subscriber to receive targeted information that may be of interest or use for due diligence, job search or other evaluation or research purposes.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>VALIDATING ACCESS TO CONTENT SUBSCRIBERS</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            For Subscribers, we use the Personal Data of Subscribers (i.e. login; passwords, API tokens) to validate their right to use our Services.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>DIRECT MARKETING SUBSCRIBERS</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            Customers can opt out of receiving marketing materials via email or mail at any time while receiving the Service. If you receive email, newsletter or marketing communications from us and no longer wish to receive them, please follow the removal instructions in the email or change your account settings.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>SEARCH SUBJECTS</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            We do not market to Search Subjects or commercially sell data not otherwise publicly available.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>TRACKING AND PROFILING FOR CUSTOMER SERVICE PURPOSES, STATISTICAL AND TO FULFILL OBLIGATIONS TO OUR SUBSCRIBERS.</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>SUBSCRIBERS</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            Subscriber tracking is used to improve the Services and Site and includes: length of use; time and date service was used; search queries; API requests; and how the service was accessed (app; desktop; phone).
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>DATA SUBJECT PROFILING</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            We combine the personal data and information from publicly available websites and present this information to our Subscribers via our Service. Our Subscribers are able to search publicly accessible sources using APIs or a search dashboard. This allows our Customers to do due diligence on Individuals with whom they wish to build a business relationship or collect and use data for other legitimate purposes.
                          </p>
                          <p className="fw fs-4 pt-5">
                            We also offer a service that uses APIs to structure hundreds or thousands of web pages into a single, searchable index. In doing so, the Subscriber may collect and create profiles containing Personal Data.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>Targeting</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            Our Subscribers can understand your subject matter expertise, areas of interest, services offered, or subjects you are interested in thereby allowing our Subscriber(s) to target Search Subjects or other search criteria.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>View and Evaluate Your Content</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            Via our service, a Subscriber can search and categorize your content, which may be given an automatically generated “relevance” score or be categorized/labeled, by APIDojo’s technology; The Subscriber can extract data from the aggregated data that matches their query or create graphs based on the indexes they build.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>Automated Decision-making</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            We use automated techniques such as visual layout analysis and classification, computer vision, text analytics, machine learning, knowledge fusion to identify and classify data, and extract and understand data on webpages which can be used to develop algorithms for even more robust automation. APIDojo does not use the output of automated data processing as the sole basis for any decisions regarding a Search Subject.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>HOW DO WE SHARE YOUR DATA?</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            We will not sell, rent, or share your personal data with third parties outside of APIDojo without your consent, except in the following ways:
                          </p>
                          <p className="fw fs-4 pt-5">
                            Subscribers (Applicable to Search Subjects): Our subscribers are typically businesses or institutions and come from all sectors. We share your data with our subscribers by allowing them access to extract data from the internet through our online services.
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h3>THIRD PARTY SEVICE PROVIDERS</h3>
                          </p>
                          <p className="fw fs-4 pt-5">
                            Third Parties that collect and share Personal Data with us regarding Search Subjects, Trial Users, or Subscribers:
                          </p>
                          <p className="fw fs-4 pt-5">
                            <h4>Google Analytics</h4>
                          </p>
                          <p className="fw fs-4 pt-5">
                            Google Analytics collects information such as how often users visit our site, what pages they visit when they do so, and what other sites they used prior to coming to this site. We use the information we get from Google Analytics only to improve this site, but in an anonymous form. Google Analytics collects only the IP address assigned to you on the date you visit this site and assigns a user ID code, rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics with personally identifiable information.
                          </p>
                          <p className="fw fs-4 pt-5">
                            Google uses this information to analyze your use of the website, to generate reports about website activities for website operators and to provide further services related to website and internet use. Google may also share such information with third parties to the extent it is legally required to do so and/or to the extent third parties process data on behalf of Google. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this site, the cookie cannot be used by anyone but Google. Google’s ability to use and share information collected by Google Analytics about your visits to this site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy and Data Processing Amendment. You can prevent Google Analytics from recognizing you on return visits to this site by disabling cookies on your browser. You may block Google Analytics on some browsers with the help of a browser add-on if you do not want us to use this website analysis.
                          </p>
                          <p className="fw fs-4 pt-5">
                            This add-on can be downloaded at: http://tools.google.com/dlpage/gaoptout?hl=en. For more information on Google Analytics and Google’s privacy practices, please review their privacy policy at https://www.google.com/policies/privacy/
                          </p>
                          <p className="fw fs-4 pt-5">
                          </p>
                          <p className="fw fs-4 pt-5">
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    map: state.mapReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onTypeInSearchForm: (data) => {
      dispatch(typeInSearchForm(data));
    },
    onShowHideModal: (state) => {
      dispatch(showHideModal(state));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
