import React, { Component } from 'react';

class ErrorItem extends Component {
    render()
    {
        return (
            <div className="alert alert-danger">
                {this.props.msg}
            </div>
        );
    }
}
export default ErrorItem;