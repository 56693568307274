import React, { Component } from 'react';
import ErrorItem from './ErrorItem';
import InfoItem from './InfoItem';
import { connect } from 'react-redux';
import { printOut, showProgress } from './../actions/index';
import * as _ from 'lodash';
import { changeInForm, listApplication, typeInApplicationForm } from '../actions/applicationAction';

const TAG = "CreateApplication";

class CreateApplication extends Component {
  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
  }

  onCreateApplication = () => {
    // printOut(TAG, "onCreateApplication", "");
  }

  onChange = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    var { user } = this.props;
    var newData = {
      ...user,
      [name]: value
    }
    // console.log('onChange', user);
    this.props.onChangeInForm(newData);
  }

  render() {
    var { user, application } = this.props;
    // printOut(TAG, "application", application)
    var applications = [], keys = [];
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-flush">
              <div className="card-header">
                {/* <div className="card-title">
                  <h3>
                    Create an application
                  </h3>
                </div> */}
                <div className="card-toolbar">
                  {/* <div className="d-flex justify-content-end"
                    data-kt-subscription-table-toolbar="base">
                    <button type="button" className="btn btn-primary" onClick={this.onCreateApplication}>Create an application</button>
                  </div>
                  <div className="d-flex justify-content-end align-items-center d-none"
                    data-kt-subscription-table-toolbar="selected">
                    <div className="fw-bolder me-5">
                      <span className="me-2"
                        data-kt-subscription-table-select="selected_count"></span>Selected
                    </div>
                    <button type="button" className="btn btn-danger"
                      data-kt-subscription-table-select="delete_selected">Delete
                      Selected</button>
                  </div> */}
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                  <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50">
                    <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
                      {/* <form className="form w-100" id="kt_sign_in_form"> */}
                        <div className="card-body">
                          <div className="text-start mb-10">
                            <h3>
                              Create an application
                            </h3>
                          </div>
                          <div className="fv-row mb-8">
                            <input type="text" placeholder="Name" name="name" autoComplete="off"
                              className="form-control form-control-solid" value={application.name} onChange={this.onChange} />
                          </div>
                          <div className="fv-row mb-7">
                            <input type="text" placeholder="Description" name="description" autoComplete="off"
                              className="form-control form-control-solid" value={application.description} onChange={this.onChange} />
                          </div>
                          {/* {msgsForm} */}
                          <div className="d-flex flex-stack">
                            <button id="kt_sign_in_submit" className="btn btn-primary me-2 flex-shrink-0" type='button' onClick={this.onCreateApplication}>
                              <span className="indicator-label" data-kt-translate="sign-in-submit">Create</span>
                              <span className="indicator-progress">
                                <span data-kt-translate="general-progress">Please wait...</span>
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            </button>
                          </div>
                        </div>
                      {/* </form> */}
                      <div className="m-0">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    application: state.applicationReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onListApplication: (params) => {
      dispatch(showProgress());
      dispatch(listApplication(params));
    },
    onChangeInForm: (params) => {
      dispatch(changeInForm(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateApplication);