import { printOut } from '../actions';
import * as types from './../constants/ActionTypes';

var initialState = {
    applicationId: "",
    subscriberId:"",
    path:"",
    status:"",
    name: "",
    description: "",
    range:"",
    pagination: {
        current_page: 1,
        size: 10,
        total: 0
    },
    success: false,
    message: [],
    data: [],
    logs:[],
    subscribers: [],
    dataApi: {},
    dataResponseTime: {},
    dataStatus: {}
};

const TAG = "applicationReducer";

const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.APPLICATION_LIST_COMPLETED:
            // printOut(TAG, 'APPLICATION_LIST_COMPLETED ', action);
            let appId = "";
            if (action && action.data && action.data.data && action.data.data.length > 0) {
                appId = action.data.data[0].id;
            }
            state = {
                ...state,
                // ...action.data,
                applicationId: appId
            }
            // printOut(TAG, 'APPLICATION_LIST_COMPLETED ', state);
            return state;
        case types.CHANGE_IN_FORM:
            state = {
                ...state,
                ...action.data
            };
            state.pagination.current_page=1;
            return state;
        case types.APPLICATION_GET_LOG_COMPLETED:
            // printOut(TAG, 'APPLICATION_GET_LOG_COMPLETED ', action);
            state = {
                ...state,
                ...action.data,
                logs:action.data.data,
                subscriberId:action.data.subscriberId,
                path:action.data.path,
                range:action.data.range,
                subscriberId:action.data.subscriberId,
                pagination: action.data.metadata.pagination
            };
            // printOut(TAG, 'APPLICATION_GET_LOG_COMPLETED ', state);
            return state;
        case types.APPLICATION_GET_ANALYTIC_COMPLETED:
            // printOut(TAG, 'APPLICATION_GET_ANALYTIC_COMPLETED ', action);
            state = {
                ...state,
                dataApi: (action.data && action.data.aggs == "field:api") ? {...action.data.data} : state.dataApi,
                dataResponseTime: (action.data && action.data.aggs == "avg:response-time") ?  {...action.data.data} : state.dataResponseTime,
                dataStatus: (action.data && action.data.aggs == "field:status") ?  {...action.data.data} : state.dataStatus,
            };
            // printOut(TAG, 'APPLICATION_GET_ANALYTIC_COMPLETED ', state);
            return state;
        case types.APPLICATION_GET_SUBSCRIBERS_COMPLETED:
            // printOut(TAG, 'APPLICATION_GET_SUBSCRIBERS_COMPLETED ', action);
            state = {
                ...state,
                subscribers:action.data.data
            };
            // printOut(TAG, 'APPLICATION_GET_SUBSCRIBERS_COMPLETED ', state);
            return state;
        default:
            if (action && action.data && action.data.success == true) {
                state = {
                    ...state,
                    isLoading: false,
                    message: []
                }
            }
            else if (action && action.data && action.data.success == false && action.data.message) {
                state = {
                    ...state,
                    isLoading: false,
                    message: action.data.message
                }
            }
            return state;
    }
};

export default applicationReducer;