import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RedocStandalone } from 'redoc';

class Documentation extends Component {
  componentDidMount() {

    // console.log("componentDidMount");
  }
  onShowModal = () => {
    // console.log("onShowModal");
    this.props.onShowHideModal(true);
  }
  onHideModal = () => {
    // console.log("onHideModal");
    this.props.onShowHideModal(false);
  }
  render() {
    console.log('this.props.match.params.id', this.props.match.params.id);
    var id = this.props.match.params.id;
    return (
      <React.Fragment>
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <RedocStandalone
              specUrl={`https://apidojo.net/json/${id}.json`}
              options={{
                hideDownloadButton: true,
                nativeScrollbars: true,
                theme: {
                  spacing: {
                    sectionVertical: 20,
                    sectionHorizontal: 20
                  }
                },
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    map: state.mapReducer
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onShowHideModal: (state) => {
      dispatch(showHideModal(state));
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Documentation);
