import React, { Component } from 'react';
import { connect } from 'react-redux';
import { printOut, showProgress } from './../actions/index';
import * as _ from 'lodash';
import { listSubscription, getSubscriptionDetail, renewKey } from '../actions/subscriptionAction';
import Pagination from 'react-js-pagination';
import SubscriptionListItem from './SubscriptionListItem';
import { Tooltip } from 'react-tooltip'
import { createInvoice, resetInvoiceInfo } from '../actions/invoiceAction';
import { Redirect } from 'react-router-dom';
import InfoItem from './InfoItem';
import ErrorItem from './ErrorItem';

const TAG = "Subscriptions";

class Subscriptions extends Component {
  componentDidMount() {
    var { subscriptionList } = this.props;
    var params = {
      page: (subscriptionList && subscriptionList.pagination && subscriptionList.pagination.current_page) ? subscriptionList.pagination.current_page : 1,
      size: (subscriptionList && subscriptionList.pagination && subscriptionList.pagination.size) ? subscriptionList.pagination.size : 10
    }
    this.props.onListSubscription(params);
    if (this.props.match.params.id) {
      // printOut(TAG, 'this.props.match.params.id', this.props.match.params.id ? this.props.match.params.id : "");
      var params = {
        subscriptionId: this.props.match.params.id
      }
      this.props.onGetDetail(params);
    }
  }

  componentWillUnmount() {
    // printOut(TAG, 'componentWillUnmount', "");
    this.props.onResetInvoiceInfo();
  }

  onGetDetail = (subscription) => {
    var params = {
      subscriptionId: subscription.id
    }
    this.props.onGetDetail(params);
  }

  onExtend = () => {
    var { subscriptionList } = this.props;
    var params = {
      subscription: subscriptionList.subscription.id
    }
    // printOut(TAG, 'onExtend', params);
    this.props.onExtend(params);
  }

  onRenewKey = () => {
    var { subscriptionList } = this.props;
    var params = {
      page: (subscriptionList && subscriptionList.pagination && subscriptionList.pagination.current_page) ? subscriptionList.pagination.current_page : 1,
      size: (subscriptionList && subscriptionList.pagination && subscriptionList.pagination.size) ? subscriptionList.pagination.size : 10,
      subscriptionId: subscriptionList.subscription.id
    }
    this.props.onRenewKey(params);
  }

  onPageChange = (page) => {
    var { subscriptionList } = this.props;
    if (subscriptionList) {
      var params = {
        page: page,
        size: subscriptionList.pagination.size
      };
      this.props.onListSubscription(params);
    }
  }

  onSizeChange = (e) => {
    var { subscriptionList } = this.props;
    if (subscriptionList) {
      var params = {
        page: subscriptionList.pagination.current_page,
        size: parseInt(e.target.value)
      };
      this.props.onListSubscription(params);
    }
  }

  render() {
    // $('[data-toggle="tooltip"]').tooltip(); //eslint
    var { subscriptionList, invoice } = this.props;
    // printOut(TAG, 'render subscriptionList', subscriptionList);
    // printOut(TAG, 'render invoice', invoice);
    if (invoice && invoice.invoice && invoice.invoice.id) {
      // printOut(TAG, 'Redirect', "invoices/" + invoice.invoice.id);
      // window.location.replace("/invoices/" + invoice.invoice.id);
      this.props.history.push("/invoices/" + invoice.invoice.id);
    }
    var msgsForm = [], msgsFormDetail = [];;
    if (subscriptionList && subscriptionList.message) {
      if (!subscriptionList.success) {
        msgsForm = subscriptionList.message.map((msg, index) => {
          return <ErrorItem key={index} msg={msg} />
        });
      }
    }
    if (invoice && invoice.message) {
      if (!invoice.success) {
        msgsFormDetail = invoice.message.map((msg, index) => {
          return <ErrorItem key={index} msg={msg} />
        });
      }
    }

    var now = new Date().getTime();
    var subscriptions = [], keys = [];
    // printOut(TAG, 'render', subscriptionList);
    if (subscriptionList && subscriptionList.subscriptions && subscriptionList.subscriptions.length > 0) {
      subscriptions = subscriptionList.subscriptions.map((keyItem, index) => {
        // var item = subscriptionList.subscriptions[index];
        var subscriptionListItem = {
          id: keyItem.id,
          api: subscriptionList.metadata[keyItem.api].name,
          plan: subscriptionList.metadata[keyItem.plan].name,
          subscribed_by: subscriptionList.metadata[keyItem.subscribed_by].name,
          status: keyItem.status,
          created_at: keyItem.created_at
        };
        return <SubscriptionListItem index={index + 1} key={index} subscriptionListItem={subscriptionListItem} onGetDetail={this.onGetDetail} />
      });
    }

    if (subscriptionList && subscriptionList.subscription && subscriptionList.subscription.keys && subscriptionList.subscription.keys.length > 0) {
      keys = subscriptionList.subscription.keys.map((keyItem, index) => {
        var expireAt = (now > (new Date(keyItem.expire_at)).getTime()) ? "Expired" : (keyItem.expire_at ? moment(new Date(keyItem.expire_at)).from(new Date()) : "N/A");
        var tooltip = {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          "title": "Tooltip on top"
        }
        return <div className="mb-7" key={index}>
          <div className="input-group">
            <input type="text" className="form-control form-control-solid" value={keyItem.key ? keyItem.key : "N/A"} readOnly />
            <button className="btn btn-icon btn-light" data-tooltip-id="my-tooltip" data-tooltip-content="Copied" onClick={() => { navigator.clipboard.writeText(keyItem.key ? keyItem.key : "N/A") }}>
              <i className="ki-duotone ki-copy fs-2 text-muted"></i>
            </button>
            <Tooltip id="my-tooltip" place="top" openOnClick="true" />
          </div>
          <span className="form-text text-muted">Generate on : <b>{keyItem.created_at ? (keyItem.created_at.substring(0, keyItem.created_at.indexOf(".")).replace('T', ' ')) : "N/A"}</b></span><br />
          <span className="form-text text-muted">Expire : <b>{expireAt}</b></span>
        </div>
      });
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className={`col-lg-${(subscriptionList && subscriptionList.subscription && subscriptionList.subscription.keys) ? 8 : 12}`}>
            <div className="card card-flush mb-10 ">
              <div className="card-body pt-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5">
                      <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                          <th className="min-w-125px text-center">API</th>
                          <th className="min-w-125px text-center">Plan</th>
                          <th className="min-w-125px text-center">Create at</th>
                          <th className="min-w-125px text-center">Subscribed by</th>
                          <th className="min-w-125px text-center">Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 fw-bold">
                        {
                          subscriptions.length > 0 ?
                            subscriptions
                            :
                            <tr>
                              <td colSpan={5} className="text-center fs-5">There is no data available</td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  {msgsForm}
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_length">
                      <select className="form-select form-select-sm form-select-solid" value={(subscriptionList && subscriptionList.pagination && subscriptionList.pagination.size) ? subscriptionList.pagination.size : 10} onChange={this.onSizeChange}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination
                        itemClass='paginate_button page-item'
                        disabledClass='disabled'
                        itemClassNext='next'
                        itemClassPrev='previous'
                        linkClass='page-link'
                        activeClass='active'
                        innerClass="pagination"
                        activePage={(subscriptionList && subscriptionList.pagination && subscriptionList.pagination.current_page) ? subscriptionList.pagination.current_page : 1}
                        itemsCountPerPage={(subscriptionList && subscriptionList.pagination && subscriptionList.pagination.size) ? subscriptionList.pagination.size : 10}
                        totalItemsCount={(subscriptionList && subscriptionList.pagination && subscriptionList.pagination.total) ? subscriptionList.pagination.total : 0}
                        onChange={this.onPageChange}
                        pageRangeDisplayed={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            (subscriptionList && subscriptionList.subscription && subscriptionList.subscription.keys) ?
              <div className="col-lg-4">
                <div className="card card-flush mb-10">
                  <div className="card-header">
                    <div className="card-title">
                      <h3>Subscription detail</h3>
                    </div>
                  </div>
                  <div className="card-body pt-0 fs-6">
                    <div className="mb-7">
                      <table className="table fs-6 fw-bold gs-0 gy-2 gx-2">
                        <tbody>
                          <tr >
                            <td className="text-gray-400">Plan:</td>
                            <td className="text-gray-800">
                              {subscriptionList.subscription && subscriptionList.metadata && subscriptionList.subscription.plan && subscriptionList.metadata[subscriptionList.subscription.plan] && subscriptionList.metadata[subscriptionList.subscription.plan].name ? subscriptionList.metadata[subscriptionList.subscription.plan].name : "N/A"}
                            </td>
                          </tr>
                          <tr >
                            <td className="text-gray-400">Start date:</td>
                            <td className="text-gray-800">{subscriptionList.subscription.created_at ? subscriptionList.subscription.created_at.substring(0, subscriptionList.subscription.created_at.indexOf("T")) : "N/A"}
                            </td>
                          </tr>
                          <tr >
                            <td className="text-gray-400">End date:</td>
                            <td className="text-gray-800">{subscriptionList.subscription.end_at ? subscriptionList.subscription.end_at.substring(0, subscriptionList.subscription.end_at.indexOf("T")) : "N/A"}</td>
                          </tr>
                          <tr >
                            <td className="text-gray-400">Status:</td>
                            <td>
                              <span className={`badge badge-light-${subscriptionList.subscription.status === "ACCEPTED" ? "success" : (subscriptionList.subscription.status === "REJECTED" ? "danger" : (subscriptionList.subscription.status === "CLOSED" ? "dark" : "warning"))}`}>{subscriptionList.subscription.status ? subscriptionList.subscription.status : "N/A"}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {msgsFormDetail}
                    {
                      (subscriptionList.subscription.status === "ACCEPTED") ?
                        <div className="mb-7">
                          <button className="btn btn-primary me-4" data-bs-toggle="modal"
                            data-bs-target="#kt_modal_2">Extend subscription</button>
                        </div>
                        :
                        (
                          (subscriptionList.subscription.status === "PENDING") ?
                            <div className="mb-7">
                              <button className="btn btn-primary me-4" data-bs-toggle="modal"
                                data-bs-target="#kt_modal_2">Create a new invoice</button>
                            </div>
                            :
                            ""
                        )
                    }
                    <div className="separator separator-dashed mb-7"></div>
                    <div className="mb-7">
                      <label className="fs-6 fw-bold form-label">API key</label>
                      {
                        keys.length > 0 ?
                          keys
                          :
                          <div className="text-center">There is no available key</div>
                      }
                    </div>
                    {
                      (keys.length > 0 && subscriptionList.subscription.status === "ACCEPTED") ?
                        <div className="mb-0">
                          <button className="btn btn-primary me-4" data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1">Renew key</button>
                        </div>
                        :
                        ""
                    }
                  </div>
                </div>
              </div>
              :
              ""
          }
          <div className="modal fade" tabIndex={-1} id="kt_modal_1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm</h5>
                  <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" >
                    <img alt="close" className="svg-icon svg-icon-2x" src="assets/media/icons/duotune/arrows/arr061.svg" />
                  </div>
                </div>
                <div className="modal-body">
                  <p className="fs-5">Are you sure you want to generate a new key?
                    The old key will be expired in 2 hours.
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light" data-bs-dismiss="modal" > Cancel </button>
                  <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={this.onRenewKey}> OK </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex={-1} id="kt_modal_2">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm</h5>
                  <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" >
                    <img alt="close" className="svg-icon svg-icon-2x" src="assets/media/icons/duotune/arrows/arr061.svg" />
                  </div>
                </div>
                <div className="modal-body">
                  <p className="fs-5">Are you sure you want to extend or create
                    a new invoice for this subscription?
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-light" data-bs-dismiss="modal" > Cancel </button>
                  <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={this.onExtend}> OK </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    subscriptionList: state.subscriptionReducer,
    invoice: state.invoiceReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onListSubscription: (params) => {
      dispatch(showProgress());
      dispatch(listSubscription(params));
    },
    onRenewKey: (params) => {
      dispatch(showProgress());
      dispatch(renewKey(params));
    },
    onGetDetail: (params) => {
      dispatch(showProgress());
      dispatch(getSubscriptionDetail(params));
    },
    onExtend: (params) => {
      dispatch(showProgress());
      dispatch(createInvoice(params));
    },
    onResetInvoiceInfo: () => {
      dispatch(resetInvoiceInfo());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);

