import React, { Component } from 'react';

class Plan extends Component {
    render() {
        var { planItem, subscriptions, onChoosePlan } = this.props;
        var hasSubscribed = false;
        if (subscriptions && subscriptions.data) {
            subscriptions.data.map((keyItem, index) => {
                if (keyItem.plan == planItem.id) {
                    hasSubscribed = true;
                }
            });
        }

        // console.log('planItem.characteristics.lengh',planItem.characteristics.length);
        var price;
        if (planItem.characteristics.length > 0) {
            //([0-9]*) USD
            const reguarExp = new RegExp('([0-9]*) USD');
            var extractedValue = reguarExp.exec(planItem.characteristics[0])[1];
            price = extractedValue ? new Intl.NumberFormat('en-US').format(extractedValue) : "N/A";
            // console.log('price',price);
        }
        return (
            <div className="col-xl-4">
                <div className="d-flex h-100 align-items-center">
                    <div
                        className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                        <div className="mb-7 text-center">
                            <h1 className="text-dark mb-5 fw-bolder">{planItem.name}</h1>
                            <div className="text-center">
                                <span className="mb-2 text-primary">$</span>
                                <span className="fs-3x fw-bold text-primary">{price}</span>
                                <span className="fs-7 fw-semibold opacity-50">/
                                    <span data-kt-element="period">month</span></span>
                            </div>
                        </div>
                        <div className="w-100 mb-10">
                            <div className="d-flex align-items-center mb-5">
                                <span
                                    className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">No hidden costs. No surprise overage charges</span>
                                <i className="ki-duotone ki-check-circle fs-1 text-success">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                                <span
                                    className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Proxy layer included</span>
                                <i className="ki-duotone ki-check-circle fs-1 text-success">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                                <span
                                    className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Overcome bot defender layer</span>
                                <i className="ki-duotone ki-check-circle fs-1 text-success">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                                <span
                                    className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">Rate limiting at 10 calls per second</span>
                                <i className="ki-duotone ki-check-circle fs-1 text-success">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </div>
                        </div>
                        <a className={`btn btn-sm btn-primary ${hasSubscribed == true ? "disabled" : ""}`} onClick={() => onChoosePlan(planItem)}>{hasSubscribed == true ? "Subscribed" : "Choose"}</a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Plan;