import React, { Component } from 'react';
import ErrorItem from './ErrorItem';
import InfoItem from './InfoItem';
import { connect } from 'react-redux';
import { printOut, showProgress } from './../actions/index';
import * as _ from 'lodash';
import { listInvoice, getInvoiceDetail } from '../actions/invoiceAction';
import Pagination from 'react-js-pagination';
import InvoiceListItem from './InvoiceListItem';

const TAG = "Invoices";

class Invoices extends Component {
  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
    var { invoiceList } = this.props;
    var params = {
      page: (invoiceList && invoiceList.pagination && invoiceList.pagination.current_page) ? invoiceList.pagination.current_page : 1,
      size: (invoiceList && invoiceList.pagination && invoiceList.pagination.size) ? invoiceList.pagination.size : 10
    }
    this.props.onListInvoice(params);
  }

  onGetDetail = (invoice) => {
    // printOut(TAG, "onDetail", invoice);
    // var params = {
    //   invoiceId: invoice.id
    // }
    // this.props.onGetDetail(params);
  }

  onPageChange = (page) => {
    // printOut(TAG, "onPageChange", page);
    var { invoiceList } = this.props;
    if (invoiceList) {
      var params = {
        page: page,
        size: invoiceList.pagination.size
      };
      this.props.onListInvoice(params);
    }
  }

  onSizeChange = (e) => {
    var { invoiceList } = this.props;
    if (invoiceList) {
      var params = {
        page: invoiceList.pagination.current_page,
        size: parseInt(e.target.value)
      };
      this.props.onListInvoice(params);
    }
  }

  render() {
    var { invoiceList } = this.props;
    // printOut(TAG, "invoiceList", invoiceList)
    var invoices = [], keys = [];
    if (invoiceList && invoiceList.invoices && invoiceList.invoices.length > 0) {
      invoices = invoiceList.invoices.map((keyItem, index) => {
        // var item = invoiceList.invoices[index];
        // printOut(TAG, "item", item)
        // printOut(TAG, "invoiceList.metadata[item.api].name", invoiceList.metadata[item.api].name)
        var invoiceListItem = {
          id: keyItem.id,
          apiName: keyItem.apiName,
          planName: keyItem.planName,
          status: keyItem.status,
          paymentMethod: keyItem.paymentMethod,
          paymentLink: keyItem.paymentLink,
          paymentId: keyItem.paymentId,
          amount: keyItem.amount,
          createdAt: keyItem.createdAt,
          expiredAt: keyItem.expiredAt
        };
        return <InvoiceListItem index={index + 1} key={index} invoiceListItem={invoiceListItem} onGetDetail={this.onGetDetail} />
      });
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-flush mb-10">
              <div className="card-body pt-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5">
                      <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                          <th className="min-w-125px text-center">API</th>
                          <th className="min-w-125px text-center">Plan</th>
                          <th className="min-w-125px text-center">Create at</th>
                          <th className="min-w-125px text-center">Due at</th>
                          <th className="min-w-125px text-center">Amount</th>
                          <th className="min-w-125px text-center">Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 fw-bold">
                        {
                          invoices.length > 0 ?
                            invoices
                            :
                            <tr>
                              <td colSpan={6} className="text-center fs-5">There is no data available</td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_length">
                      <label>
                        <select className="form-select form-select-sm form-select-solid" value={(invoiceList && invoiceList.pagination && invoiceList.pagination.size) ? invoiceList.pagination.size : 10} onChange={this.onSizeChange}>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                        </select></label>
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination
                        itemClass='paginate_button page-item'
                        disabledClass='disabled'
                        itemClassNext='next'
                        itemClassPrev='previous'
                        linkClass='page-link'
                        activeClass='active'
                        innerClass="pagination"
                        activePage={(invoiceList && invoiceList.pagination && invoiceList.pagination.current_page) ? invoiceList.pagination.current_page : 1}
                        itemsCountPerPage={(invoiceList && invoiceList.pagination && invoiceList.pagination.size) ? invoiceList.pagination.size : 10}
                        totalItemsCount={(invoiceList && invoiceList.pagination && invoiceList.pagination.total) ? invoiceList.pagination.total : 0}
                        onChange={this.onPageChange}
                        pageRangeDisplayed={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    invoiceList: state.invoiceReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onListInvoice: (params) => {
      dispatch(showProgress());
      dispatch(listInvoice(params));
    },
    onGetDetail: (params) => {
      dispatch(getInvoiceDetail(params));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

