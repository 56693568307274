import React, { Component } from 'react';
import ErrorItem from './ErrorItem';
import InfoItem from './InfoItem';
import { connect } from 'react-redux';
import { logout, resetPassword } from './../actions/userAction';
import { printOut, showProgress } from './../actions/index';
import * as _ from 'lodash';

const TAG = "Settings";

class Settings extends Component {

  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
  }

  onResetPassword = (e) => {
    // // e.preventDefault();
    var { user } = this.props;
    if (user.user.email.trim().length === 0) {
      alert("Email is required!");
      return;
    }
    let params = {
      username: user.user.email
    }
    // printOut(TAG, "onSubmit", params);
    this.props.onResetPassword(params);
    this.props.onLogout();
    this.props.history.push('/verify-email')
  }

  render() {
    var { user } = this.props;
    // printOut(TAG, "user", user);
    var msgsForm;
    if (user && user.message && user.message.length > 0) {
      msgsForm = user.message.map((msg, index) => {
        if (user.success)
          return <InfoItem key={index} msg={msg} />
        else
          return <ErrorItem key={index} msg={msg} />
      });
    }

    return (
      <React.Fragment>
        <div className="row mb-10 gy-5 g-xl-10">
          <div className="col-xl-4">
            <div className="card card-flush h-xl-100 mb-5">
              <div className="card-header pt-7">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">Password</span>
                  <span className="text-gray-400 mt-1 fw-semibold fs-6">Last Updated 05/18/2023</span>
                </h3>
                <div className="card-toolbar">
                  <a onClick={this.onResetPassword}
                    className="btn btn-sm btn-light">Change password</a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-4">
                      <div className="card card-flush h-xl-100 mb-5">
                        <div className="card-header pt-7">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Product Delivery</span>
                            <span className="text-gray-400 mt-1 fw-semibold fs-6">1M Products
                              Shipped so far</span>
                          </h3>
                          <div className="card-toolbar">
                            <a href="../../demo1/dist/apps/ecommerce/sales/details.html"
                              className="btn btn-sm btn-light">Order Details</a>
                          </div>
                        </div>
                      </div>
                    </div> */}
        </div>
        {/* <div className="card mb-5 mb-xl-10">
                    <div className="card-header border-0 cursor-pointer" role="button"
                      data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Sign-in Method</h3>
                      </div>
                    </div>
                    <div id="kt_account_settings_signin_method" className="collapse show">
                      <div className="card-body border-top p-9">
                        <div className="d-flex flex-wrap align-items-center mb-10">
                          <div id="kt_signin_password">
                            <div className="fs-6 fw-bold mb-1">Password</div>
                            <div className="fw-semibold text-gray-600">************</div>
                          </div>
                          <div id="kt_signin_password_edit" className="flex-row-fluid d-none">
                            <form id="kt_signin_change_password" className="form"
                              novalidate="novalidate">
                              <div className="row mb-1">
                                <div className="col-lg-4">
                                  <div className="fv-row mb-0">
                                    <label for="currentpassword"
                                      className="form-label fs-6 fw-bold mb-3">Current
                                      Password</label>
                                    <input type="password"
                                      className="form-control form-control-lg form-control-solid"
                                      name="currentpassword" id="currentpassword" />
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="fv-row mb-0">
                                    <label for="newpassword"
                                      className="form-label fs-6 fw-bold mb-3">New
                                      Password</label>
                                    <input type="password"
                                      className="form-control form-control-lg form-control-solid"
                                      name="newpassword" id="newpassword" />
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="fv-row mb-0">
                                    <label for="confirmpassword"
                                      className="form-label fs-6 fw-bold mb-3">Confirm New
                                      Password</label>
                                    <input type="password"
                                      className="form-control form-control-lg form-control-solid"
                                      name="confirmpassword" id="confirmpassword" />
                                  </div>
                                </div>
                              </div>
                              <div className="form-text mb-5">Password must be at least 8
                                character and contain symbols</div>
                              <div className="d-flex">
                                <button id="kt_password_submit" type="button"
                                  className="btn btn-primary me-2 px-6">Update
                                  Password</button>
                                <button id="kt_password_cancel" type="button"
                                  className="btn btn-color-gray-400 btn-active-light-primary px-6">Cancel</button>
                              </div>
                            </form>
                          </div>
                          <div id="kt_signin_password_button" className="ms-auto">
                            <button className="btn btn-light btn-active-light-primary">Reset
                              Password</button>
                          </div>
                        </div>
                        <div
                          className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                          <i className="ki-duotone ki-shield-tick fs-2tx text-primary me-4">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            <div className="mb-3 mb-md-0 fw-semibold">
                              <h4 className="text-gray-900 fw-bold">Secure Your Account</h4>
                              <div className="fs-6 text-gray-700 pe-7">Two-factor authentication
                                adds an extra layer of security to your account. To log in,
                                in addition you'll need to provide a 6 digit code</div>
                            </div>
                            <a href="#"
                              className="btn btn-primary px-6 align-self-center text-nowrap"
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_two_factor_authentication">Enable</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onResetPassword: (user) => {
      dispatch(showProgress());
      dispatch(resetPassword(user));
    },
    onLogout: () => {
      dispatch(showProgress());
      dispatch(logout());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

