import React, { Component } from 'react';
import { connect } from 'react-redux';
import { typeInRegisterForm, register, getDetail } from './../actions/userAction';
import { printOut, showProgress } from './../actions/index';
import { Link, Redirect } from 'react-router-dom';
import InfoItem from './InfoItem';
import ErrorItem from './ErrorItem';

const TAG = "SignUp";
class SignUp extends Component {
  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
    this.props.onGetDetail();
  }

  onChange = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    var { user } = this.props;
    var newData = {
      ...user,
      [name]: value
    }
    this.props.onTypeInRegisterForm(newData);
  }

  onSubmit = (e) => {
    // e.preventDefault();
    var { user } = this.props;
    let params = {
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname
    }
    if (user.email.trim().length === 0) {
      alert("Email is required!");
      return;
    }
    if (user.firstname.trim().length === 0) {
      alert("First name is required!");
      return;
    }
    if (user.lastname.trim().length === 0) {
      alert("Last name is required!");
      return;
    }
    // printOut(TAG, "onSubmit", params);
    this.props.onRegister(params);
  }

  render() {
    var { user } = this.props;
    var msgsForm;
    if (user && user.message && user.message.length > 0) {
      msgsForm = user.message.map((msg, index) => {
        if (!user.success)
          return <ErrorItem key={index} msg={msg} />
      });
    }

    if (user.authorizedStatus == 1) {
      if (user && user.last_visit_url && user.last_visit_url != "/users/sign-in" && user.last_visit_url != "/users/sign-up") {
        return <Redirect to={user.last_visit_url} />
      }
      else {
        return <Redirect to={"/dashboard"} />
      }
    }

    if (user.success) {
      return <Redirect to='/verify-email' />
    }

    return (
      <React.Fragment>
        <div className="app-blank d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
            <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
              <div className="d-flex flex-stack py-2">
                <div className="me-2">
                  <Link to="/" className="btn btn-icon bg-light rounded-circle">
                  <i className="ki-duotone ki-black-left fs-2 text-gray-800"></i>
                  </Link>
                </div>
                <div className="m-0">
                  <span className="text-gray-400 fw-bold fs-5 me-2" data-kt-translate="sign-up-head-desc">Already a member ?</span>
                  <Link to="/users/sign-in" className="link-primary fw-bold fs-5" data-kt-translate="sign-up-head-link">Sign In</Link>
                </div>
              </div>
              <div className="py-20">
                <form className="form w-100" noValidate="noValidate" id="kt_sign_up_form" data-kt-redirect-url="../../demo1/dist/authentication/layouts/fancy/sign-in.html" action="#">
                  <div className="text-start mb-10">
                    <h1 className="text-dark mb-3 fs-3x" data-kt-translate="sign-up-title">Create an Account</h1>
                  </div>
                  <div className="row fv-row mb-7">
                    <div className="col-xl-6">
                      <input className="form-control form-control-lg form-control-solid" type="text" placeholder="First Name" name="firstname" value={user.firstname} onChange={this.onChange} autoComplete="off" data-kt-translate="sign-up-input-first-name" />
                    </div>
                    <div className="col-xl-6">
                      <input className="form-control form-control-lg form-control-solid" type="text" placeholder="Last Name" name="lastname" value={user.lastname} onChange={this.onChange} autoComplete="off" data-kt-translate="sign-up-input-last-name" />
                    </div>
                  </div>
                  <div className="fv-row mb-7">
                    <input className="form-control form-control-lg form-control-solid" type="email" placeholder="Email" name="email" value={user.email} onChange={this.onChange} autoComplete="off" data-kt-translate="sign-up-input-email" />
                  </div>
                  {msgsForm}
                  <div className="d-flex flex-stack">
                    <button id="kt_sign_up_submit" className="btn btn-primary" type="button" onClick={this.onSubmit}>
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="m-0">
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    index: state.indexReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetDetail: () => {
      dispatch(showProgress());
      dispatch(getDetail());
    },
    onRegister: (user) => {
      dispatch(showProgress());
      dispatch(register(user));
    },
    onTypeInRegisterForm: (data) => {
      dispatch(typeInRegisterForm(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
