import { printOut } from '../actions';
import * as types from './../constants/ActionTypes';

// var x_xsrf_token = (localStorage && localStorage.getItem('x_xsrf_token'))?localStorage.getItem('x_xsrf_token'):"";
var initialState = {
    success: false,
    authorizedStatus: 2, //0|1|2
    message: [],
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    confirm_password: "",
    last_visit_url: "",
    token:"",
    user:{}
};

const TAG = "userReducer";

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_UPDATE_LAST_VISIT_URL:
            // printOut(TAG,'USER_UPDATE_LAST_VISIT_URL ',action);
            state = {
                ...state,
                last_visit_url: action.url
            }
            // printOut(TAG,'USER_UPDATE_LAST_VISIT_URL', state);
            return state;
        case types.USER_UNAUTHORIZED:
            // printOut(TAG,'USER_UNAUTHORIZED',action);
            // printOut(TAG,'window.location.pathname',window.location.pathname);
            state = {
                ...state,
                ...action.data,
                last_visit_url: window.location.pathname,
                authorizedStatus: 0
            }
            // printOut(TAG,'USER_UNAUTHORIZED ',state);
            return state;
        case types.USER_WRONG_PASSWORD: // handle all error in same case
            //printOut(TAG,'USER_WRONG_PASSWORD ',action);
            state = {
                ...state,
                error: action.data.error,
                msgsPassForm: action.data.message
            }
            return state;
        case types.USER_REGISTER_COMPLETED:
            //printOut(TAG,'USER_REGISTER_COMPLETED ',action);
            state = {
                ...state,
                ...action.data,
                email: "",
                password:"",
                firstname: "",
                lastname: ""
            }
            return state;
        case types.USER_CONFIRM_COMPLETED:
            //printOut(TAG,'USER_CONFIRM_COMPLETED ',action);
            state = {
                ...state,
                ...action.data,
                email: "",
                password:"",
                firstname: "",
                lastname: ""
            }
            return state;
        case types.USER_GET_COMPLETED:
            // printOut(TAG,'USER_GET_COMPLETED ',action);
            state = {
                ...state,
                // ...action.data,
                user:action.data.data,
                authorizedStatus: 1
            }
            // printOut(TAG,'USER_GET_COMPLETED ',state);
            return state;
        case types.USER_LOGOUT_COMPLETED:
            //printOut(TAG,'USER_LOGOUT_COMPLETED',action);
            state = {
                ...state,
                ...action.data,
                authorizedStatus: 2,
                email: '',
                password: '',
                last_visit_url:'',
                user:{}
            }
            return state;
        case types.USER_PASSWORD_CHANGE_COMPLETED:
            //printOut(TAG,'USER_PASSWORD_CHANGE_COMPLETED',action);
            state = {
                ...state,
                ...action.data,
                msgsPassForm: action.data.message,
                password: "",
                new_password: "",
                retype_password: ""
            }
            return state;
        case types.USER_LOGIN_COMPLETED:
            // printOut(TAG, 'USER_LOGIN_COMPLETED ', action);
            state = {
                ...state,
                ...action.data,
                authorizedStatus: 1,
                email: '',
                password: ''
            }
            // printOut(TAG, 'USER_LOGIN_COMPLETED ', state);
            return state;
        case types.USER_RESET_PASSWORD_COMPLETED:
            //printOut(TAG,'USER_RESET_PASSWORD_COMPLETED',action);
            state = {
                ...state,
                ...action.data,
                email: ''
            }
            return state;
        case types.TYPE_IN_LOGIN_FORM:
            state = {
                ...state,
                ...action.data
            };
            return state;
        case types.TYPE_IN_REGISTER_FORM:
            state = {
                ...state,
                ...action.data
            };
            return state;
        case types.TYPE_IN_PROFILE_FORM:
            state = {
                ...state,
                ...action.data
            };
            return state;
        case types.TYPE_IN_RESET_PASSWORD_FORM:
            state = {
                ...state,
                ...action.data
            };
            return state;
        default:
            if (action && action.data && action.data.success == true) {
                state = {
                    ...state,
                    isLoading: false,
                    message: []
                }
            }
            else if (action && action.data && action.data.success == false && action.data.message) {
                state = {
                    ...state,
                    isLoading: false,
                    message: action.data.message
                }
            }
            return state;
    }
};

export default userReducer;