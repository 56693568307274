import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, getDetail, updateLastVisitUrl } from './../actions/userAction';
import { changeCurrentUrl, printOut, showProgress } from '../actions';

const TAG = "MainMenu";

class MainMenu extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
    this.props.onGetDetail();
  }

  onLogout = (e) => {
    // printOut(TAG, 'onLogout', '');
    this.props.onLogout();
  }

  onChangeCurrentUrl = (url) => {
    // printOut(TAG,'onChangeCurrentUrl',url);
    this.props.onChangeCurrentUrl(url);
  }

  render() {
    // KTComponents.init(); // init all KT components inclusing menu at corner
    var { user, index } = this.props;
    var path = window.location.pathname;
    // printOut(TAG, 'user', user);
    if (user) {
      if (user.success == true && user.authorizedStatus == 2) {
        // printOut(TAG,'redirect',user.last_visit_url);
        return <Redirect to="/users/sign-in" />
      }
      else if (user.authorizedStatus == 0) {
        const regex = new RegExp(/apis\/[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+/);
        // printOut(TAG, 'path', path);
        if (path.startsWith('/dashboard') == 1 || path.startsWith('/settings') == 1 || path.startsWith('/subscriptions') == 1 || path.startsWith('/invoices') == 1 || path.startsWith('/logs') == 1 || regex.test(path)) {
          return <Redirect to="/users/sign-in" />
        }
      }
    }

    return (
      <React.Fragment>
        <div className="app-header" data-kt-sticky="true" data-kt-sticky-name="app-header" data-kt-sticky-offset="{default: '200px', lg: '300px'}" style={{ animationDuration: 'animation-duration: 0.3s, top: 0px' }} data-kt-sticky-enabled="true">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center flex-equal">
                <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none" id="kt_landing_menu_toggle">
                  <i className="ki-duotone ki-abstract-14 fs-2hx">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
                <a href="/">
                  <img alt="Logo" src="../assets/media/logos/ApiDojoLogo.jpg" className="h-60px h-lg-70px app-sidebar-logo-default theme-light-show" />
                  {/* <img alt="Logo" src="assets/media/logos/landing-dark.svg" className="logo-sticky h-20px h-lg-25px" /> */}
                </a>
              </div>
              <div className="d-lg-block" id="kt_header_nav_wrapper">
                <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true" data-kt-drawer-name="landing-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="200px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">
                  <div className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold" id="kt_landing_menu">
                    <div className="menu-item">
                      <Link to="/" className={`menu-link nav-link py-3 px-4 px-xxl-6 ${location.pathname === "/" || index.currentUrl == "/" ? "active" : ""}`}
                        data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" onClick={() => this.onChangeCurrentUrl("/")}>Home</Link>
                    </div>
                    <div className="menu-item">
                      <Link to="/apis" className={`menu-link nav-link py-3 px-4 px-xxl-6 ${location.pathname.startsWith("/apis") || index.currentUrl.startsWith("/apis") ? "active" : ""}`}
                        data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" onClick={() => this.onChangeCurrentUrl("/apis")}>API Hub</Link>
                    </div>
                    <div className="menu-item">
                      <Link to="/tutorial" className={`menu-link nav-link py-3 px-4 px-xxl-6 ${location.pathname.startsWith("/tutorial") || index.currentUrl.startsWith("/tutorial") ? "active" : ""}`}
                        data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" onClick={() => this.onChangeCurrentUrl("/tutorial")}>Tutorial</Link>
                    </div>
                    <div className="menu-item">
                      <Link to="/documentations" className={`menu-link nav-link py-3 px-4 px-xxl-6 ${location.pathname.startsWith("/documentations") || index.currentUrl.startsWith("/documentations") ? "active" : ""}`}
                        data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" onClick={() => this.onChangeCurrentUrl("/documentations")}>Documentations</Link>
                    </div>
                    <div className="menu-item">
                      {
                        (user && user.authorizedStatus === 1) ?
                          <Link to="/dashboard" className={`menu-link nav-link py-3 px-4 px-xxl-6 ${location.pathname.startsWith("/dashboard") || index.currentUrl.startsWith("/dashboard") ? "active" : ""}`}
                            data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" onClick={() => this.onChangeCurrentUrl("/dashboard")}>Dashboard</Link>
                          :
                          ""
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-equal text-end ms-1">
                {
                  (user && user.authorizedStatus === 1) ?
                    <div className="app-navbar-item ms-1 ms-md-3" id="kt_header_user_menu_toggle">
                      <div id="menuDiv" className="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip='bottom'>
                        <img src="../assets/media/avatars/blank.png" alt="user" />
                      </div>
                      <div id="menuTopup" className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                        data-kt-menu="true" data-popper-placement="bottom-end">
                        <div className="menu-item px-3">
                          <div className="menu-content d-flex align-items-center px-3">
                            <div className="d-flex flex-column">
                              <div className="fw-bold d-flex align-items-center fs-5">
                                {(user && user.user && user.user.display_name) ? user.user.display_name : 'N/A'}
                              </div>
                              <a href="#" className="fw-semibold text-muted text-hover-primary fs-7"> {(user && user.user && user.user.email) ? user.user.email : 'N/A'}</a>
                            </div>
                          </div>
                        </div>
                        <div className="separator my-2"></div>
                        <div className="menu-item px-5">
                          <Link to="/dashboard" className="menu-link px-5" onClick={() => this.onChangeCurrentUrl("/dashboard")}>Dashboard</Link>
                        </div>
                        <div className="menu-item px-5">
                          <Link to="/subscriptions" className="menu-link px-5" onClick={() => this.onChangeCurrentUrl("/subscriptions")}>Subscriptions</Link>
                        </div>
                        <div className="menu-item px-5">
                          <Link to="/invoices" className="menu-link px-5" onClick={() => this.onChangeCurrentUrl("/invoices")}>Invoices</Link>
                        </div>
                        <div className="menu-item px-5">
                          <Link to="/logs" className="menu-link px-5" onClick={() => this.onChangeCurrentUrl("/logs")}>Logs</Link>
                        </div>
                        <div className="separator my-2"></div>
                        <div className="menu-item px-5 my-1">
                          <Link to="/settings" className="menu-link px-5" onClick={() => this.onChangeCurrentUrl("/settings")}>Settings</Link>
                        </div>
                        <div className="menu-item px-5">
                          <a href="#" className="menu-link px-5" onClick={this.onLogout}>Sign Out</a>
                        </div>
                      </div>
                    </div>
                    :
                    <Link to="/users/sign-in" className="btn btn-success">Sign In</Link>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    index: state.indexReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetDetail: () => {
      dispatch(showProgress());
      dispatch(getDetail());
    },
    onLogout: () => {
      dispatch(showProgress());
      dispatch(logout());
    },
    onChangeCurrentUrl: (url) => {
      dispatch(changeCurrentUrl(url));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);