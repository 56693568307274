import React, { Component } from 'react';
import { connect } from 'react-redux';
import { printOut, showProgress } from './../actions/index';
import Pagination from 'react-js-pagination';
import { listPlan } from '../actions/planAction';
import { listApplication } from '../actions/applicationAction';
import { back, next, listSubscription, choosePlan, createSubscription } from '../actions/subscriptionAction';
import Plan from './Plan';
import { Redirect } from 'react-router-dom';

const TAG = "Pricing";

class Pricing extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    // printOut(TAG, 'this.props.match.params.id', this.props.match.params.id);
    this.props.onListApplication();
    var paramsPlan = {
      api: this.props.match.params.id
    }
    this.props.onListPlan(paramsPlan);
    var paramsSubscription = {
      api: this.props.match.params.id,
      statuses: "ACCEPTED,PAUSED,PENDING",
      size: -1
    }
    this.props.onListSubscription(paramsSubscription);
  }

  onChangePayment = (payment) => {
    // printOut(TAG, "onChangePayment", payment);
  }

  onChoosePlan = (plan) => {
    // printOut(TAG, "onChoosePlan", plan);
    this.props.onChoosePlan(plan);
  }

  onNext = () => {
    this.props.onNext();
  }

  onBack = () => {
    this.props.onBack();
  }

  onCreateSubscription = () => {
    var { applications, subscriptions } = this.props;
    // printOut(TAG, "app", applications.data.length>0?applications.data[0].id:'');
    // printOut(TAG, "plan", subscriptions.plan.id);
    var params = {
      plan: subscriptions.plan.id,
      application: applications.applicationId,
      paymentMethod: subscriptions.paymentMethod ? subscriptions.paymentMethod : ""
    }
    // printOut(TAG, "params", params);
    this.props.onCreateSubscription(params);
  }

  onShowModal = () => {
    // printOut(TAG,"onShowModal");
    this.props.onShowHideModal(true);
  }

  onHideModal = () => {
    // printOut(TAG,"onHideModal");
    this.props.onShowHideModal(false);
  }

  render() {
    var { plans, subscriptions } = this.props;
    // printOut(TAG, 'subscriptions', subscriptions);
    if (subscriptions && subscriptions.invoice && subscriptions.invoice.paymentLink) {
      window.location.replace(subscriptions.invoice.paymentLink);
    }
    var planList = [];
    if (plans && plans.data && plans.data.length > 0) {
      planList = plans.data.map((keyItem, index) => {
        return <Plan index={index + 1} key={index} planItem={keyItem} subscriptions={subscriptions} onChoosePlan={this.onChoosePlan} />
      });
    }
    var price;
    if (subscriptions && subscriptions.plan && subscriptions.plan.characteristics && subscriptions.plan.characteristics.length > 0) {
      const reguarExp = new RegExp('([0-9]*) USD');
      var extractedValue = reguarExp.exec(subscriptions.plan.characteristics[0])[1];
      price = extractedValue ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(extractedValue) : "N/A";
    }
    return (
      <React.Fragment>
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                {/* <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1
                      className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                      Pricing</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                      <li className="breadcrumb-item text-muted">
                        <a href="#"
                          className="text-muted text-hover-primary">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <span className="bullet bg-gray-400 w-5px h-2px"></span>
                      </li>
                      <li className="breadcrumb-item text-muted">Plans & Pricing</li>
                    </ul>
                  </div>
                </div> */}
              </div>
              <div id="kt_app_content" className="app-content flex-column-fluid mb-10">
                <div id="kt_app_content_container" className="app-container container-xxl">
                  <div className="card">
                    <div className="card-body">
                      <div className="stepper stepper-links d-flex flex-column pt-15"
                        id="kt_create_account_stepper">
                        <div className="stepper-nav mb-5">
                          <div className={`stepper-item ${subscriptions.step === 1 ? 'current' : ''}`} data-kt-stepper-element="nav">
                            <h3 className="stepper-title">Plans</h3>
                          </div>
                          <div className={`stepper-item ${subscriptions.step === 2 ? 'current' : ''}`} data-kt-stepper-element="nav">
                            <h3 className="stepper-title">Payment methods</h3>
                          </div>
                          <div className={`stepper-item ${subscriptions.step === 3 ? 'current' : ''}`} data-kt-stepper-element="nav">
                            <h3 className="stepper-title">Summary</h3>
                          </div>
                        </div>
                        <div className="mx-auto" noValidate="noValidate">
                          <div className={`${subscriptions.step === 1 ? 'current' : ''}`} data-kt-stepper-element="content">
                            <div className="d-flex flex-column">
                              <div className="mb-7 text-center">
                                <h1 className="fs-2hx fw-bold mb-5">Choose the Right Plan For You</h1>
                              </div>
                              <div className="tab-content mb-7">
                                <div id="kt_tab_one_time_payment" className="tab-pane fade show active" role="tabpanel">
                                  <div className="row g-10">
                                    <div className="mb-7 text-center text-danger">
                                      *You pay for a number of fixed requests on a monthly basis, the number of requests will be reset at the end of the plan.<br/>
                                      The remaining requests of previous month won't be added to the next month.
                                    </div>
                                  </div>
                                  <div className="row g-10">
                                    {planList}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`${subscriptions.step === 2 ? 'current' : ''}`} data-kt-stepper-element="content">
                            <div className="w-100">
                              <div className="pb-8 pb-lg-10">
                                <h2 className="fw-bold text-dark">Choose your payment method :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                              </div>
                              <div className="py-1 d-flex flex-stack flex-wrap">
                                <div className="d-flex align-items-center collapsible toggle"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#kt_create_new_payment_method_1">
                                  <div
                                    className="btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2">
                                    <i
                                      className="ki-duotone ki-minus-square toggle-on text-primary fs-2">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                    <i
                                      className="ki-duotone ki-plus-square toggle-off fs-2">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                    </i>
                                  </div>
                                  <img src="assets/media/svg/card-logos/mastercard.svg"
                                    className="w-40px me-3" alt="" />
                                  <div className="me-3">
                                    <div className="d-flex align-items-center fw-bold">
                                      By cryptocurrency
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex my-3 ms-9">
                                  <label
                                    className="form-check form-check-custom form-check-solid me-5">
                                    <input className="form-check-input" type="radio"
                                      name="payment_method" checked="checked" onChange={this.onChangePayment} value="cryptomus" />
                                  </label>
                                </div>
                              </div>
                              <div className="separator separator-dashed"></div>
                              <div className="py-1">
                                <div className="py-3 d-flex flex-stack flex-wrap">
                                  <div className="d-flex align-items-center collapsible toggle"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#kt_create_new_payment_method_2">
                                    <div
                                      className="btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2">
                                      <i
                                        className="ki-duotone ki-minus-square toggle-on text-primary fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                      </i>
                                      <i
                                        className="ki-duotone ki-plus-square toggle-off fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                      </i>
                                    </div>
                                    <img src="assets/media/svg/card-logos/visa.svg"
                                      className="w-40px me-3" alt="" />
                                    <div className="me-3">
                                      <div className="d-flex align-items-center fw-bold">By Card</div>
                                      <div className="text-muted">Comming soon</div>
                                    </div>
                                  </div>
                                  {/* <div className="d-flex my-3 ms-9">
                                    <label
                                      className="form-check form-check-custom form-check-solid me-5">
                                      <input className="form-check-input" type="radio" name="payment_method" />
                                    </label>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`${subscriptions.step === 3 ? 'current' : ''}`} data-kt-stepper-element="content">
                            <div className="w-100">
                              <div className="pb-8 pb-lg-4">
                                <h2 className="fw-bold text-dark">Your selected plan : {subscriptions.plan.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                              </div>
                              <div className="mb-4">
                                <div
                                  className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                  <i
                                    className="ki-duotone ki-information fs-2tx text-warning me-4">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                  </i>
                                  <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                      <h4 className="text-gray-900 fw-bold">What's included?</h4>
                                      <div className="fs-6 text-gray-700">Proxy layer</div>
                                      <div className="fs-6 text-gray-700">Overcome bot defender layer</div>
                                      <div className="fs-6 text-gray-700">Rate limiting at 10 calls per second</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="separator separator-dashed mb-7"></div>
                              <div className="mb-7 text-end">
                                {/* <span className="badge badge-light-info me-2">10,000 requests</span> */}
                                <span className="fs-5 fw-semibold  text-gray-600">{price} / month</span>
                              </div>
                              <div className="text-danger text-justify ">
                                *We do not charge you to extend the plan automatically at the end of the period.<br />An invoice will be generated and send to you some days before the expiration.<br />You should pay the invoice ASAP, once it is expired, you have to subscribe to the API again.
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-stack pt-10">
                            {
                              subscriptions.step > 1 ?
                                <button type="button" className="btn btn-lg btn-light-primary me-3" onClick={this.onBack}>
                                  <i className="ki-duotone ki-arrow-left fs-4 me-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>Back</button>
                                : ''
                            }
                            {
                              subscriptions.step === 3 ?
                                <button type="button" className="btn btn-lg btn-primary me-3" onClick={this.onCreateSubscription}>
                                  <span className="indicator-label">Pay Now
                                    <i className="ki-duotone fs-4 ms-1 me-0">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i></span>
                                  <span className="indicator-progress">Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                                : ''
                            }
                            {
                              (3 > subscriptions.step && subscriptions.step > 1) ?
                                <button type="button" className="btn btn-lg btn-primary" onClick={this.onNext}>Continue
                                  <i className="ki-duotone ki-arrow-right fs-4 ms-1 me-0">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                </button> : ''
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    plans: state.planReducer,
    applications: state.applicationReducer,
    subscriptions: state.subscriptionReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateSubscription: (params) => {
      dispatch(showProgress());
      dispatch(createSubscription(params));
    },
    onListSubscription: (params) => {
      dispatch(showProgress());
      dispatch(listSubscription(params));
    },
    onListApplication: (params) => {
      dispatch(showProgress());
      dispatch(listApplication(params));
    },
    onListPlan: (params) => {
      dispatch(showProgress());
      dispatch(listPlan(params));
    },
    onChoosePlan: (plan) => {
      dispatch(next());
      dispatch(showProgress());
      dispatch(choosePlan(plan));
      dispatch(listApplication());
    },
    onNext: () => {
      dispatch(next());
    },
    onBack: () => {
      dispatch(back());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
