import React, { Component } from 'react';
import { printOut } from '../actions';
const TAG = "SubscriptionListItem";
class SubscriptionListItem extends Component {
    render() {
        var { index, subscriptionListItem, onGetDetail, onExtend } = this.props;
        return (
            <tr>
                <td>{subscriptionListItem.api}</td>
                <td className="text-end">{subscriptionListItem.plan}</td>
                <td className="text-center">{subscriptionListItem.created_at ? subscriptionListItem.created_at.substring(0, subscriptionListItem.created_at.indexOf("T")) : "N/A"}</td>
                <td className="text-center">{subscriptionListItem.subscribed_by}</td>
                <td className="text-center">
                    <div className={`badge badge-light-${subscriptionListItem.status === "ACCEPTED" ? "success" : (subscriptionListItem.status === "REJECTED" ? "danger" : (subscriptionListItem.status === "CLOSED" ? "dark" : "warning"))}`}>{subscriptionListItem.status}</div>
                </td>
                <td className="text-end">
                    <button className="btn btn-sm btn-light btn-active-light-primary" onClick={() => onGetDetail(subscriptionListItem)}>Detail</button>
                </td>
            </tr>
        );
    }
}
export default SubscriptionListItem;