import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, Link } from 'react-router-dom';
import Home from './Home';
import MainMenu from './MainMenu';
import Pricing from './Pricing';
import Terms from './Terms';
import ApiHub from './ApiHub';
// import CreateSubscription from './CreateSubscription';
import ResetPassword from './ResetPassword';
import SubHolder from './SubHolder';
import Contact from './Contact';
import Privacy from './Privacy';
import Documentations from './Documentations';
import Tutorial from './Tutorial';

const TAG = "MainHolder";

class MainHolder extends Component {
  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
  }

  onChangeCurrentUrl = (url) => {
    // printOut(TAG,'onChangeCurrentUrl',url);
    this.props.onChangeCurrentUrl(url);
  }

  render() {
    // var { user } = this.props;
    // var path = window.location.pathname;
    // printOut(TAG,'user',user);
    return (
      <React.Fragment>
        <MainMenu />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contact-us" exact component={Contact} />
          <Route path="/apis" exact component={ApiHub} />
          <Route path="/apis/:id" exact component={Pricing} />
          <Route path="/tutorial" exact component={Tutorial} />
          <Route path="/documentations" exact component={Documentations} />
          {/* <Route path="/subscriptions/create-subscription/:id" exact component={CreateSubscription} /> */}
          <Route path="/terms" exact component={Terms} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/users/reset-password" exact component={ResetPassword} />
          <Route component={SubHolder} />
        </Switch>
        <div id="kt_app_footer" className="app-footer">
          <div
            className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted fw-semibold me-1">2023&copy;</span>
              <a href="https://apidojo.net" target="_blank"
                className="text-gray-800 text-hover-primary">APIDojo</a>
            </div>
            <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
              <li className="menu-item">
                <Link to="/" className="menu-link px-2" onClick={() => this.onChangeCurrentUrl("/")}>Home</Link>
              </li>
              <li className="menu-item">
                <Link to="/contact-us" className="menu-link px-2" onClick={() => this.onChangeCurrentUrl("/contact-us")}>Contact Us</Link>
              </li>
              <li className="menu-item">
                <Link to="/apis" className="menu-link px-2" onClick={() => this.onChangeCurrentUrl("/apis")}>API Hub</Link>
              </li>
              <li className="menu-item">
                <Link to="/terms" className="menu-link px-2" onClick={() => this.onChangeCurrentUrl("/terms")}>Terms & Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    index: state.indexReducer
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeCurrentUrl: (url) => {
      dispatch(changeCurrentUrl(url));
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MainHolder);