import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import { printOut } from '../actions';
const TAG = "LogListItem";
class LogListItem extends Component {
    render() {
        var { index, logItem, onGetDetail } = this.props;
        var date = logItem.timestamp? new Intl.DateTimeFormat('us', { 
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', second: '2-digit',hourCycle: 'h23'
        }).format(logItem.timestamp):"N/A";
        var status = logItem.status.toString();
        // printOut(TAG,"render",(status.startsWith("2")));
        return (
            <tr>
                {/*  */}
                <td className="text-center">{date}</td>
                <td className="text-center"><span className={`badge badge-${logItem.status.toString().startsWith("2")?"success":((logItem.status.toString().startsWith("3") || logItem.status.toString().startsWith("4"))?"warning":"danger")}`}>{logItem.status}</span></td>
                <td className="text-center">{logItem.apiName}</td>
                <td className="text-center">{logItem.planName}</td>
                <td className="text-center">{logItem.method}</td>
                <td>{logItem.path}</td>
                <td className="text-end">{logItem.responseTime} ms</td>
            </tr>
        );
    }
}
export default LogListItem;