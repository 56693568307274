import {combineReducers} from 'redux';
import userReducer from './userReducer';
import planReducer from './planReducer';
import subscriptionReducer from './subscriptionReducer';
import invoiceReducer from './invoiceReducer';
import applicationReducer from './applicationReducer';
import indexReducer from './indexReducer';

const appReducers = combineReducers({
    indexReducer,
    userReducer,
    planReducer,
    applicationReducer,
    subscriptionReducer,
    invoiceReducer
});

export default appReducers;