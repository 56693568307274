import axios from 'axios';
import * as Config from './../constants/Config';
import { generateHash, getLocalStorage } from './../actions/index';
import { Buffer } from 'buffer';

export function callApiWithoutToken(endpoint, method = 'GET', body, params) {
  var authParams = params ? generateHash(params) : generateHash({});
  var authHeader = Buffer.from(`${Config.AUTH}`, "base64").toString();
  var uuidHeader = Buffer.from(`${Config.UUID}`, "base64").toString();
  return axios({
    url: `${Config.API_URL}/${endpoint}`,
    method: method,
    headers: {"X-Xsrf-Token":getLocalStorage("x_xsrf_token"),[uuidHeader]:authParams.uuid,[authHeader]: authParams.hash},
    params: params,
    data: body,
    withCredentials:true
  })
  .catch((error) => {
    console.log(error);
  });
};

export function callApiWithToken(endpoint, method = 'GET', body, params, headers) {
  var authParams = params ? generateHash(params) : generateHash({});
  var authHeader = Buffer.from(`${Config.AUTH}`, "base64").toString();
  var uuidHeader = Buffer.from(`${Config.UUID}`, "base64").toString();
  return axios({
    url: `${Config.API_URL}/${endpoint}`,
    method: method,
    headers: {"X-Xsrf-Token":getLocalStorage("x_xsrf_token"),[uuidHeader]:authParams.uuid,[authHeader]: authParams.hash},
    params: params,
    data: body,
    withCredentials:true
  })
  .catch((error) => {
    console.log(error);
  });
};