import React, { Component } from 'react';
import { NavLink, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, getDetail, updateLastVisitUrl } from './../actions/userAction';
import { changeCurrentUrl, printOut, showProgress } from '../actions';

const TAG = "SubMenu";

class SubMenu extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    // printOut(TAG, "componentDidMount", "");
    this.props.onGetDetail();
  }

  onLogout = (e) => {
    // printOut(TAG, 'onLogout', '');
    this.props.onLogout();
  }

  onChangeCurrentUrl = (url) => {
    // printOut(TAG,'onChangeCurrentUrl',url);
    this.props.onChangeCurrentUrl(url);
  }

  render() {
    // KTComponents.init(); // init all KT components inclusing menu at corner
    var { user, index } = this.props;
    var path = window.location.pathname;
    // printOut(TAG, 'user', user);
    if (user) {
      if (user.success == true && user.authorizedStatus == 2) {
        // printOut(TAG,'redirect',user.last_visit_url);
        return <Redirect to="/users/sign-in" />
      }
      else if (user.authorizedStatus == 0) {
        const regex = new RegExp(/apis\/[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+/);
        // printOut(TAG, 'path', path);
        if (path.startsWith('/dashboard') || path.startsWith('/settings') || path.startsWith('/subscriptions') || path.startsWith('/invoices') || path.startsWith('/logs') || regex.test(path)) {
          return <Redirect to="/users/sign-in" />
        }
      }
    }
    return (
      <React.Fragment>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body pt-0 pb-0">
            {
              (location.pathname.startsWith("/dashboard") || location.pathname.startsWith("/subscriptions") || location.pathname.startsWith("/invoices") || location.pathname.startsWith("/logs") || location.pathname.startsWith("/settings")
               || index.currentUrl.startsWith("/dashboard") || index.currentUrl.startsWith("/subscriptions") || index.currentUrl.startsWith("/invoices") || index.currentUrl.startsWith("/logs")  || index.currentUrl.startsWith("/settings")) ? 
               <ul
               className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
               <li className="nav-item mt-2">
                 <Link to="/dashboard" className={`nav-link text-active-primary ms-0 me-10 py-5 ${(location.pathname.startsWith("/dashboard")|| index.currentUrl.startsWith("/dashboard")) ? "active" : ""}`} onClick={() => this.onChangeCurrentUrl("/dashboard")}>Dashboard</Link>
               </li>
               <li className="nav-item mt-2">
                 <Link to="/subscriptions" className={`nav-link text-active-primary ms-0 me-10 py-5 ${(location.pathname.startsWith("/subscriptions")|| index.currentUrl.startsWith("/subscriptions")) ? "active" : ""}`} onClick={() => this.onChangeCurrentUrl("/subscriptions")}>Subscriptions</Link>
               </li>
               <li className="nav-item mt-2">
                 <Link to="/invoices" className={`nav-link text-active-primary ms-0 me-10 py-5 ${(location.pathname.startsWith("/invoices") || index.currentUrl.startsWith("/invoices")) ? "active" : ""}`} onClick={() => this.onChangeCurrentUrl("/invoices")}>Invoices</Link>
               </li>
               <li className="nav-item mt-2">
                 <Link to="/logs" className={`nav-link text-active-primary ms-0 me-10 py-5 ${(location.pathname.startsWith("/logs")|| index.currentUrl.startsWith("/logs")) ? "active" : ""}`} onClick={() => this.onChangeCurrentUrl("/logs")}>Logs</Link>
               </li>
               <li className="nav-item mt-2">
                 <Link to="/settings" className={`nav-link text-active-primary ms-0 me-10 py-5 ${(location.pathname.startsWith("/settings")|| index.currentUrl.startsWith("/settings")) ? "active" : ""}`} onClick={() => this.onChangeCurrentUrl("/settings")}>Settings</Link>
               </li>
             </ul>
             :
             ""
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer,
    index: state.indexReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetDetail: () => {
      dispatch(showProgress());
      dispatch(getDetail());
    },
    onLogout: () => {
      dispatch(showProgress());
      dispatch(logout());
    },
    onChangeCurrentUrl: (url) => {
      dispatch(changeCurrentUrl(url));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);