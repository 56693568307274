import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, typeInLoginForm, checkToken, typeInRegisterForm, resetPassword } from './../actions/userAction';
import { printOut, showProgress } from './../actions/index';
import { Link, NavLink, Redirect } from 'react-router-dom';
import InfoItem from './InfoItem';
import ErrorItem from './ErrorItem';

const TAG = "ResetPassword";

class ResetPassword extends Component {
  onChange = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    var { user } = this.props;
    var newData = {
      ...user,
      [name]: value
    }
    this.props.onTypeInRegisterForm(newData);
  }

  onResetPassword = (e) => {
    // e.preventDefault();
    var { user } = this.props;
    if (user.email.trim().length === 0) {
      alert("Email is required!");
      return;
    }
    let params = {
      username: user.email
    }
    // printOut(TAG, "onSubmit", params);
    this.props.onResetPassword(params);
  }

  render() {
    var { user } = this.props;
    // printOut(TAG, "user", user);
    var msgsForm;
    if (user && user.message && user.message.length > 0) {
      msgsForm = user.message.map((msg, index) => {
        if (user.success)
          return <InfoItem key={index} msg={msg} />
        else
          return <ErrorItem key={index} msg={msg} />
      });
    }

    if (user.success) {
      return <Redirect to='/verify-email' />
    }

    return (
      <React.Fragment>
        <div className="app-blank d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
            <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
              <div className="d-flex flex-stack py-2">
                <div className="me-2">
                  <Link to="/" className="btn btn-icon bg-light rounded-circle">
                    <i className="ki-duotone ki-black-left fs-2 text-gray-800"></i>
                  </Link>
                </div>
                <div className="m-0">
                  <span className="text-gray-400 fw-bold fs-5 me-2" data-kt-translate="sign-in-head-desc">Not a
                    Member yet?</span>
                  <Link to="/users/sign-up" className="link-primary fw-bold fs-5" data-kt-translate="sign-in-head-link">Sign Up</Link>
                </div>
              </div>
              <div className="py-20">
                <div className="text-start mb-10">
                  <h1 className="text-dark mb-3 fs-3x" data-kt-translate="password-reset-title">Forgot
                    Password ?</h1>
                  <div className="text-gray-400 fw-semibold fs-6" data-kt-translate="password-reset-desc">
                    Enter your email to reset your password.</div>
                </div>
                <div className="fv-row mb-10">
                  <input className="form-control form-control-solid" type="email" placeholder="Email"
                    name="email" autoComplete="off" value={user.email} onChange={this.onChange} data-kt-translate="password-reset-input-email" />
                </div>
                <div className="d-flex flex-stack">
                  <div className="m-0">
                    {/* data-kt-indicator="on" disabled */}
                    <button id="kt_password_reset_submit" className="btn btn-primary me-2" onClick={this.onResetPassword}
                      data-kt-translate="password-reset-submit">
                      <span className="indicator-label">Reset</span>
                      <span className="indicator-progress">Please wait...
                        <span
                          className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                    <Link to="/users/sign-in" className="btn btn-lg btn-light-primary fw-bold"
                      data-kt-translate="password-reset-cancel">Cancel</Link>
                  </div>
                </div>
              </div>
              <div className="m-0">
              </div>
              {/* <div className="swal2-container swal2-center swal2-backdrop-show" style={{ overflowY: "auto" }}>
                <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" className="swal2-popup swal2-modal swal2-icon-success swal2-show" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{ display: "grid" }}>
                  <button type="button" className="swal2-close" aria-label="Close this dialog" style={{ display: "none" }}>×</button><ul className="swal2-progress-steps" style={{ display: "none" }}></ul>
                  <div className="swal2-icon swal2-success swal2-icon-show" style={{ display: "flex" }}>
                    <div className="swal2-success-circular-line-left" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                    <span className="swal2-success-line-tip"></span>
                    <span className="swal2-success-line-long"></span>
                    <div className="swal2-success-ring"></div>
                    <div className="swal2-success-fix" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                    <div className="swal2-success-circular-line-right" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                  </div><img className="swal2-image" style={{ display: "none" }} />
                  <h2 className="swal2-title" id="swal2-title" style={{ display: "none" }}></h2>
                  <div className="swal2-html-container" id="swal2-html-container" style={{ display: "block" }}>We have send a password reset link to your email.</div>
                  <input className="swal2-input" style={{ display: "none" }} />
                  <input type="file" className="swal2-file" style={{ display: "none" }} />
                  <div className="swal2-range" style={{ display: "none" }}><input type="range" /><output></output></div>
                  <select className="swal2-select" style={{ display: "none" }}></select>
                  <div className="swal2-radio" style={{ display: "none" }}></div>
                  <label htmlFor="swal2-checkbox" className="swal2-checkbox" style={{ display: "none" }}>
                    <input type="checkbox" /><span className="swal2-label"></span></label><textarea className="swal2-textarea" style={{ display: "none" }}></textarea>
                  <div className="swal2-validation-message" id="swal2-validation-message" style={{ display: "none" }}></div>
                  <div className="swal2-actions" style={{ display: "flex" }}>
                    <div className="swal2-loader"></div>
                    <button type="button" className="swal2-confirm btn btn-primary" aria-label="" style={{ display: "inline-block" }}>Ok, got it!</button>
                    <button type="button" className="swal2-deny" aria-label="" style={{ display: "none" }}>No</button>
                    <button type="button" className="swal2-cancel" aria-label="" style={{ display: "none" }}>Cancel</button>
                  </div>
                  <div className="swal2-footer" style={{ display: "none" }}></div>
                  <div className="swal2-timer-progress-bar-container">
                    <div className="swal2-timer-progress-bar" style={{ display: "none" }}></div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.userReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onResetPassword: (user) => {
      dispatch(showProgress());
      dispatch(resetPassword(user));
    },
    onTypeInRegisterForm: (data) => {
      dispatch(typeInRegisterForm(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);